import React, { FC, ReactNode, useContext } from 'react';
import styled from 'styled-components';
import Footer from './footer';
import { CompanyContext } from '../../context/company-context';

const ContentContainer = styled.div`
  .contentWrapper {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    flex-grow: 1;
  },
  .fullHeight {
    flex-grow: 1;
    overflow: hidden;
    position: relative;
    display: flex;
},
`;

export type ContentProps = {
  children: ReactNode;
};

export const Content: FC<ContentProps> = ({ children }) => {
  const company = useContext(CompanyContext);

  return (
    <main>
      <ContentContainer>
        <div className="contentWrapper">
          <Footer
            companyName={company?.name}
            phoneNumber={company?.phone_number}
          />
          <div className="fullHeight">{children}</div>
        </div>
      </ContentContainer>
    </main>
  );
};
