/* eslint-disable */
import React from 'react';
import { Menu, MenuProps } from 'src/antd';
import styled from 'styled-components';

import { NavObject, NavKey } from './types';

export interface MenuTreeProps {
  config: NavObject[];
  selectedKeys: NavKey[];
  collapsed: boolean;
}
interface StyledItemSubMenuProps {
  $isSelected: boolean;
}

const AntdMenu = ({
  themeMode,
  ...rest
}: Omit<MenuProps, 'theme'> & { themeMode: MenuProps['theme'] }) => (
  <Menu {...rest} theme={themeMode} />
);

const StyledMenu = styled(AntdMenu)`
  background: inherit;

  .ant-menu-item,
  .ant-menu-submenu-title {
    display: flex;
    align-items: center;
  }
`;

// rewrite nested from parent and inline styles
const StyledMenuItem = styled(Menu.Item)<StyledItemSubMenuProps>`
  color: ${({ theme }) => theme.colors.dark100} !important;
  padding: 16px !important;
  margin: 0 !important;
  height: 54px !important;
  border-radius: ${({ theme }) => theme.borders.xs} !important;

  &&&:hover,
  &&&:active {
    background: ${({ theme }) => theme.colors.dark10} !important;
  }
  &&&.ant-menu-submenu-open {
    background: ${({ theme }) => theme.colors.dark10} !important;
  }
  ${({ $isSelected, theme }) =>
    $isSelected &&
    `
    font-weight: 600;
    background: ${theme.colors.dark100} !important;  
    color: ${theme.colors.dark10} !important;
    &&&:hover,
    &&&:active {
      color: ${theme.colors.dark10} !important;
      background: ${theme.colors.dark100} !important;
    }
    &&&.ant-menu-submenu-open {
      color: ${theme.colors.dark10} !important;
      background: ${theme.colors.dark100} !important;
    }
  `}
`;

const StyledMenuSub = styled(Menu.SubMenu)<StyledItemSubMenuProps>`
  color: ${({ theme }) => theme.colors.dark100} !important;

  ${({ $isSelected, theme }) =>
    $isSelected &&
    `
      &&&{
        div {
          color: ${theme.colors.dark100} !important;
        }
      } 
      &&&:hover {
        div {
          color: ${theme.colors.dark100} !important;
        }
      }
      &&&.ant-menu-submenu-open {
        div {
          color: ${theme.colors.dark100} !important;
        }
      }
      .ant-menu-submenu-arrow {
        color: ${theme.colors.dark100} !important;
      }
  `}

  @media screen and (min-width: 992px) {
    &&&:hover {
      div {
        color: ${({ theme }) => theme.colors.dark100} !important;
        background: ${({ theme }) => theme.colors.dark10} !important;
      }
    }
    &&&.ant-menu-submenu-open {
      div {
        background: ${({ theme }) => theme.colors.dark10} !important;
      }
    }
    ${({ $isSelected, theme }) =>
      $isSelected &&
      `
      color: ${theme.colors.dark10} !important;
      &&&{
        color: ${theme.colors.dark10} !important;
        div {
          background: ${theme.colors.dark100} !important; 
        }
      } 
      &&&:hover {
        div {
          color: ${theme.colors.dark10} !important;
          background: ${theme.colors.dark100} !important;
        }
      }
      &&&.ant-menu-submenu-open {
        div {
          color: ${theme.colors.dark10} !important;
          background: ${theme.colors.dark100} !important;
        }
      }
      .ant-menu-submenu-arrow {
        color: ${theme.colors.dark10} !important;
      }
  `}
  }
`;

const MenuTree: React.FC<MenuTreeProps> = ({
  config,
  selectedKeys = [],
}) => {
  return (
    <StyledMenu mode="inline" themeMode="light" selectedKeys={[]}>
      {React.Children.toArray(
        config?.map((nav, i) => {
          const { icon: Icon, key, title, childs, onClick } = nav;
          return (
            <>
              {childs && childs.length ? (
                <StyledMenuSub
                  key={key}
                  icon={
                    <Icon
                      style={{
                        fontSize: '1.25rem',
                        width: '1.25rem',
                        lineHeight: 0,
                      }}
                    />
                  }
                  title={title}
                  $isSelected={selectedKeys[0] === key ? true : false}
                  onTitleClick={() => onClick({ order: 0, nav })}
                >
                  <>
                    {childs?.map((childNav) => {
                      const {
                        icon: childIcon,
                        key: childKey,
                        title: childTitle,
                        onClick: onChildClick,
                      } = childNav;
                      return (
                        <StyledMenuItem
                          key={childKey}
                          $isSelected={
                            selectedKeys[1] === childKey ? true : false
                          }
                          onClick={() =>
                            onChildClick({ order: 1, nav: childNav })
                          }
                        >
                          {childTitle}
                        </StyledMenuItem>
                      );
                    })}
                  </>
                </StyledMenuSub>
              ) : (
                <StyledMenuItem
                  key={key}
                  icon={
                    <Icon
                      style={{
                        fontSize: `1.25rem`,
                        width: '1.25rem',
                        lineHeight: 0.9,
                      }}
                    />
                  }
                  onClick={() => onClick({ order: 0, nav })}
                  $isSelected={selectedKeys[0] === key ? true : false}
                >
                  {title}
                </StyledMenuItem>
              )}
            </>
          );
        })
      )}
    </StyledMenu>
  );
};

export default MenuTree;
