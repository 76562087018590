import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Skeleton, Dropdown, Menu, Button, Empty } from 'src/antd';
import {
  CaretUpOutlined,
  CaretDownOutlined,
  MoreOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { T } from '@transifex/react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import useMediaQuery from 'use-media-antd-query/es/useMediaQuery';
import { Typography, WasteFractionTag } from 'src/ui';
import { GetTickets } from './api/ticket';
import { PropertyContext } from '../../context/property-context';
// eslint-disable-next-line import/no-cycle
import { routingMap } from '../../routing/routing-map';
// eslint-disable-next-line import/no-cycle
import {
  containerColumnLabelMap,
  Key,
} from '../main/routes/containers/exact/ui';
// eslint-disable-next-line import/no-cycle
import { useGetToken } from '../../api/config';

type WrapperProps = {
  $tickets?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 1rem 1rem 1rem;

  width: 100%;
  height: auto;

  /* Gray / gray-3 */

  background: #f5f5f5;
  border-radius: 5px;

  margin-bottom: 1rem;

  .ant-col-sm-24 {
    min-width: 100%;
  }

  .text-wrapper {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #0f3f6b;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .ant-dropdown-trigger {
    color: #262626;
  }

  .ant-btn-text {
    margin-right: -1rem;
  }

  .waste-fraction {
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.75rem;

    color: #0f3f6b;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .waste-fraction-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    padding-right: 1rem;
  }

  .mobile-content {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-top: 0.5rem;
  }

  ${({ $tickets }) =>
    $tickets &&
    `
     cursor: pointer;
     background: #FFF1F0; 
  `};

  @media screen and (min-width: 992px) {
    padding: 1.5rem;

    .text-wrapper {
      font-size: 1.25rem;
      line-height: 1.75rem;
      padding-right: 1rem;
    }

    .mobile-content {
      display: none;
    }
  }
`;

type ContainerAndCollectionProps = {
  wasteFraction: {
    name: string;
    icon_preset: string;
  };
  containerType: string | React.ReactNode;
  pickupSetting: string | React.ReactNode;
  containerId: string | React.ReactNode;
  id: string;
  tickets: number;
  ticketTypes: { id: string; name: string }[];
  price: string | React.ReactNode;
  weekday: string | React.ReactNode;
  nextCollection: string | React.ReactNode;
  state: $TSFixMe;
};

type ExpandableContentProps = {
  containerId: string;
  numberOfTickets: number;
};

const ExpandableContentWrapper = styled.div`
  background: ${(props) => props.theme.colors.white100};
  padding: 1rem;

  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 1.5rem;

  border: 1px solid #d9d9d9;
  border-radius: 0.5rem;

  .ant-skeleton-content .ant-skeleton-title {
    height: 3rem;
  }

  h3 {
    margin-bottom: 0;
  }

  .expandable-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .ticket-information-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .button-text {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .text-view {
    display: none;
  }

  .text-ticket {
    text-transform: capitalize;
  }

  .ticket-type {
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;

    margin-right: 0.5rem;

    color: #0f3f6b;
  }

  .ticket-information {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;

    color: #0f3f6b;
  }

  .divider {
    width: 100%;
    margin: 1rem 0;
    border-bottom: 1px solid #d9d9d9;
  }

  .divider:last-of-type {
    display: none;
  }

  @media screen and (min-width: 992px) {
    margin-top: 2rem;

    .ant-skeleton-content .ant-skeleton-title {
      height: 2rem;
    }

    .ticket-information-content {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .text-view {
      display: inherit;
    }

    .text-ticket {
      text-transform: lowercase;
    }
  }
`;

const ExpandableContent = ({
  containerId,
  numberOfTickets,
}: ExpandableContentProps) => {
  const [tickets, setTickets] = useState([]);
  const { id: propertyId } = useContext(PropertyContext);
  const token = useGetToken();
  const history = useHistory();

  const { isLoading } = useQuery(
    ['get-container-tickets', propertyId, containerId],
    () =>
      GetTickets({
        property_id: propertyId,
        token,
        only_open: true,
        container_Id: containerId,
      }),
    {
      onSuccess: (data) => {
        setTickets(data.data);
      },
    }
  );

  if (!isLoading && tickets.length < 1) {
    return (
      <div
        style={{
          backgroundColor: 'white',
          width: '100%',
          height: '100%',
          padding: '1rem',
          marginTop: '1rem',
        }}
      >
        <Empty description={<T _str="No tickets" />} />
      </div>
    );
  }

  if (isLoading) {
    return (
      <ExpandableContentWrapper>
        {[...Array(numberOfTickets)]?.map((e) => (
          <>
            <Skeleton active paragraph={false} key={e} />
            <div className="divider" />
          </>
        ))}
      </ExpandableContentWrapper>
    );
  }

  return (
    <ExpandableContentWrapper key={123}>
      {tickets?.map((ticket: $TSFixMe) => (
        <>
          <div className="expandable-content" key={ticket?.ticket_id}>
            <div className="ticket-information-content">
              <div className="ticket-type">{ticket?.ticket_type}</div>
              <div className="ticket-information">
                <T _str="Ordered on" />:{' '}
                {dayjs(ticket?.created_at).format('LL')}{' '}
              </div>
            </div>
            <Button
              onClick={() =>
                history.push(
                  routingMap.app.containers[':ticketId'](ticket?.id)._
                )
              }
            >
              <div className="button-text">
                <div className="text-view">
                  <T _str="View" />
                  &nbsp;
                </div>
                <div className="text-ticket">
                  <T _str="ticket" />
                </div>
              </div>
            </Button>
          </div>
          <div className="divider" />
        </>
      ))}
    </ExpandableContentWrapper>
  );
};

type ExpanderProps = {
  expanded: boolean;
  tickets: number;
};

const TicketCountWrapper = styled.div`
  padding: 0.25rem 0.5rem;
  user-select: none;
  background: #ff4d4f;
  border-radius: 22px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  color: white;
  gap: 5px;

  @media screen and (max-width: 992px) {
    font-size: 16px;
  }
`;

const ExpanderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: end;
`;

const Expander = ({ tickets, expanded }: ExpanderProps) => {
  const isMobile = useMediaQuery('(max-width: 996px)');
  return (
    <ExpanderWrapper>
      <TicketCountWrapper>
        <ExclamationCircleFilled />
        <Typography
          style={{ color: 'white' }}
          weight="semi-bold"
          variant={isMobile ? 'h6' : 'h5'}
        >
          {!isMobile ? (
            tickets
          ) : (
            <T _str="{num} unresolved tickets" num={tickets} />
          )}
        </Typography>
        {expanded && <CaretUpOutlined />}
        {!expanded && <CaretDownOutlined />}
      </TicketCountWrapper>
    </ExpanderWrapper>
  );
};

export const ContainerDisplay = ({
  wasteFraction,
  containerId,
  containerType,
  pickupSetting,
  id,
  tickets,
  ticketTypes,
  weekday,
  nextCollection,
  price,
  state,
}: ContainerAndCollectionProps) => {
  const [expanded, setExpanded] = useState(false);
  const history = useHistory();

  const containerActions = ticketTypes;

  const isMobile = useMediaQuery('(max-width: 996px)');
  return (
    <Wrapper $tickets={!!tickets} onClick={() => setExpanded(!expanded)}>
      <Row style={{ width: '100%' }}>
        {state?.map((e: Key, index: number) => {
          const numberOfColumnsToCeil = 20 % state.length;

          const colSpan =
            index < numberOfColumnsToCeil
              ? Math.ceil(20 / state.length)
              : Math.floor(20 / state.length);

          if (e === 'wasteFraction') {
            return (
              <>
                <Col lg={colSpan} sm={14} xs={14}>
                  <div className="waste-fraction-wrapper">
                    <WasteFractionTag wasteFraction={wasteFraction} />
                    <div className="waste-fraction">{wasteFraction?.name}</div>
                  </div>
                </Col>
                <Col lg={0} xs={10} sm={10}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'end',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    {tickets > 0 && !isMobile && (
                      <Expander expanded={expanded} tickets={tickets} />
                    )}
                    {!!(containerActions?.length > 0) && (
                      /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
                      <div
                        style={{ display: 'flex', height: '100%' }}
                        onClick={(d) => {
                          d.preventDefault();
                          d.stopPropagation();
                        }}
                      >
                        <Dropdown
                          trigger={['hover']}
                          overlay={
                            <Menu>
                              {containerActions
                                ?.map(
                                  ({ id: ticketTypeId, name }: $TSFixMe) => [
                                    name,
                                    () =>
                                      history.push({
                                        pathname:
                                          routingMap.app.selfService[
                                            'create/:ticketTypeId'
                                          ](ticketTypeId)._,
                                        state: {
                                          checked: [id],
                                        },
                                      }),
                                  ]
                                )
                                .map((action, i) => (
                                  <Menu.Item
                                    /* eslint-disable-next-line react/no-array-index-key */
                                    key={i}
                                    onClick={action?.[1]}
                                  >
                                    <div
                                      style={{
                                        minWidth: '100px',
                                        width: 'auto',
                                      }}
                                    >
                                      {action?.[0]}
                                    </div>
                                  </Menu.Item>
                                ))}
                            </Menu>
                          }
                        >
                          <Button
                            size="middle"
                            type="text"
                            style={{ height: '100%' }}
                          >
                            <MoreOutlined />
                          </Button>
                        </Dropdown>
                      </div>
                    )}
                  </div>
                </Col>
              </>
            );
          }

          if (e === 'containerId') {
            return (
              <Col lg={colSpan} sm={isMobile ? 24 : 0}>
                <div className="text-wrapper">
                  {isMobile && containerColumnLabelMap[e]}
                  {isMobile && `: `}
                  {containerId}
                </div>
              </Col>
            );
          }
          if (e === 'containerType') {
            return (
              <Col lg={colSpan} sm={isMobile ? 24 : 0}>
                <div className="text-wrapper">
                  {isMobile && containerColumnLabelMap[e]}
                  {isMobile && `: `}
                  {containerType}
                </div>
              </Col>
            );
          }
          if (e === 'pickupSetting') {
            return (
              <Col lg={colSpan} sm={isMobile ? 24 : 0}>
                <div className="text-wrapper">
                  {isMobile && containerColumnLabelMap[e]}
                  {isMobile && `: `}
                  {pickupSetting}
                </div>
              </Col>
            );
          }
          if (e === 'weekDay') {
            return (
              <Col lg={colSpan} sm={isMobile ? 24 : 0}>
                <div className="text-wrapper">
                  {isMobile && containerColumnLabelMap[e]}
                  {isMobile && `: `}
                  {weekday}
                </div>
              </Col>
            );
          }
          if (e === 'nextEmptying') {
            return (
              <Col lg={colSpan} sm={isMobile ? 24 : 0}>
                <div className="text-wrapper">
                  {isMobile && containerColumnLabelMap[e]}
                  {isMobile && `: `}
                  {nextCollection}
                </div>
              </Col>
            );
          }
          if (e === 'price') {
            return (
              <Col lg={colSpan} sm={isMobile ? 24 : 0}>
                <div className="text-wrapper">
                  {isMobile && containerColumnLabelMap[e]}
                  {isMobile && `: `}
                  {price}
                </div>
              </Col>
            );
          }
          return null;
        })}
        {tickets > 0 && isMobile && (
          <Col xs={24}>
            <Row style={{ marginTop: '1rem' }} justify="start">
              <Col>
                <Expander expanded={expanded} tickets={tickets} />
              </Col>
            </Row>
          </Col>
        )}
        <Col lg={4} xs={0} sm={0}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
              alignItems: 'center',
              height: '100%',
            }}
          >
            {tickets > 0 && <Expander expanded={expanded} tickets={tickets} />}
            {!!(containerActions?.length > 0) && (
              /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
              <div
                style={{ display: 'flex', height: '100%' }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Dropdown
                  trigger={['hover']}
                  overlay={
                    <Menu>
                      {containerActions
                        ?.map(({ id: ticketTypeId, name }: $TSFixMe) => [
                          name,
                          () =>
                            history.push({
                              pathname:
                                routingMap.app.selfService[
                                  'create/:ticketTypeId'
                                ](ticketTypeId)._,
                              state: {
                                checked: [id],
                              },
                            }),
                        ])
                        .map((action, index) => (
                          <Menu.Item
                            /* eslint-disable-next-line react/no-array-index-key */
                            key={index}
                            onClick={action?.[1]}
                          >
                            <div
                              style={{
                                minWidth: '100px',
                                width: 'auto',
                              }}
                            >
                              {action?.[0]}
                            </div>
                          </Menu.Item>
                        ))}
                    </Menu>
                  }
                >
                  <Button size="middle" type="text" style={{ height: '100%' }}>
                    <MoreOutlined />
                  </Button>
                </Dropdown>
              </div>
            )}
          </div>
        </Col>
      </Row>
      {/*
      <div className="mobile-content">
        <div className="text-wrapper">
          {containerType}&nbsp;/&nbsp;{pickupSetting}
        </div>
        <div className="text-wrapper">{containerId}</div>
      </div>
      */}
      {expanded && !!tickets && (
        <ExpandableContent containerId={id} numberOfTickets={tickets} />
      )}
    </Wrapper>
  );
};
