import dayjs, { Dayjs } from 'dayjs';

export const getMonthIndex = (date: Dayjs) => {
  return dayjs(date).month();
};

export const getPreviousMonthIndex = (date: Dayjs) => {
  return dayjs(date).subtract(1, 'month').month();
};

export const getNextMonthIndex = (date: Dayjs) => {
  return dayjs(date).add(1, 'month').month();
};

export const getShortMonthName = (date: Dayjs) => {
  return dayjs(date).format('MMM');
};

export const getFullMonthName = (date: Dayjs) => {
  return dayjs(date).format('MMMM');
};

export const getYear = (date: Dayjs) => {
  return dayjs(date).format('YYYY');
};

export const formatDate = (date: Dayjs) => {
  return dayjs(date).format('LL');
};

export const getMonthRangeString = (monthIndex: number, yearIndex: number) => {
  const from = dayjs()
    .set('year', yearIndex)
    .set('month', monthIndex)
    .set('date', 1);

  const to = from.clone().endOf('month');

  return `${from}, ${to}`;
};

export const getMonthRangeArray: (
  monthIndex: number,
  yearIndex: number
) => [dayjs.Dayjs, dayjs.Dayjs] = (monthIndex: number, yearIndex: number) => {
  const from = dayjs()
    .set('year', yearIndex)
    .set('month', monthIndex)
    .set('date', 1);
  const to = from.clone().endOf('month');

  return [from, to];
};

export const getMonthRangeArrayFromDayjs: (
  date: dayjs.Dayjs
) => [dayjs.Dayjs, dayjs.Dayjs] = (date: dayjs.Dayjs) => {
  const from = date.clone().startOf('month');
  const to = date.clone().endOf('month');
  return [from, to];
};

export const getCurrentMonthRange: () => [dayjs.Dayjs, dayjs.Dayjs] = () => {
  const from = dayjs().startOf('month');
  const to = dayjs().endOf('month');
  return [from, to];
};

export const monthSelectValueToMomentRange: (
  selectValue: string
) => [dayjs.Dayjs, dayjs.Dayjs] = (selectValue: string) => {
  if (typeof selectValue !== 'number') {
    const dates = selectValue.split(', ');

    return [dayjs(dates[0]), dayjs(dates[1])];
  }

  return getCurrentMonthRange();
};
