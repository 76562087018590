import { CSSProperties } from 'react';
import { DefaultTheme } from 'styled-components';

export type TVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'body-14'
  | 'body-12'
  | 'unset';

export const fontFamilies: Record<
  Partial<TVariant>,
  keyof DefaultTheme['fonts']
> = {
  h1: 'primary',
  h2: 'primary',
  h3: 'primary',
  h4: 'primary',
  h5: 'primary',
  h6: 'secondary',
  'body-14': 'secondary',
  'body-12': 'secondary',
  unset: 'secondary',
};

export const variants: Record<TVariant, CSSProperties> = {
  h1: {
    fontSize: 80,
    lineHeight: 1.1,
  },
  h2: {
    fontSize: 60,
    lineHeight: 1.1,
  },
  h3: {
    fontSize: 40,
    lineHeight: 1.3,
  },
  h4: {
    fontSize: 28,
    lineHeight: 1.3,
  },
  h5: {
    fontSize: 20,
    lineHeight: 1.4,
  },
  h6: {
    fontSize: 16,
    lineHeight: 1.4,
  },
  'body-14': {
    fontSize: 14,
    lineHeight: 1.4,
  },
  'body-12': {
    fontSize: 12,
    lineHeight: 1.3,
  },
  unset: {},
};

export type TFontWeight = 'heavy' | 'semi-bold' | 'medium' | 'regular';

export const fontWeights: Record<TFontWeight, CSSProperties> = {
  heavy: {
    fontWeight: 800,
  },
  'semi-bold': {
    fontWeight: 600,
  },
  medium: {
    fontWeight: 500,
  },
  regular: {
    fontWeight: 400,
  },
};

export type TLoadingSizes = 'small' | 'default' | 'large' | 'full';

export const loadingStylesMap: Record<TLoadingSizes, CSSProperties> = {
  small: { width: 120 },
  default: { width: 150 },
  large: { width: 250 },
  full: { width: '100%' },
};
