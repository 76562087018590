import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { createPrefixer } from '../../utils';

export type Layout1Props = {
  header: ReactNode;
  breadcrumb?: ReactNode;
  description?: ReactNode;
  mainContent: ReactNode;
};

const p = createPrefixer('wh-Layout-responsive-layout1');

const classes = {
  wrapper: p`wrapper`,
  header: p`header`,
  breadcrumb: p`breadcrumb`,
  description: p`description`,
  mainContent: p`mainContent`,
  heightContainer: p`heightContainer`,
};

const Layout1Div = styled.div`
  padding: 1rem 1rem 1rem 1rem;
  width: 100vw;
  max-width: 100vw !important;
  flex: 1;
  height: 100%;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  .${classes.header} {
    height: auto;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .${classes.breadcrumb} {
    height: auto;
    margin-bottom: 1rem;
  }

  .${classes.description} {
    height: auto;
    margin-bottom: 1rem;
  }

  .${classes.heightContainer} {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  @media screen and (min-width: 992px) {
    padding: 3rem 4rem 3rem 4rem;
    width: calc(100vw - 17.5rem);

    .${classes.header} {
      margin-bottom: 3rem;
    }

    .${classes.breadcrumb} {
      margin-bottom: 2rem;
    }

    .${classes.description} {
      width: 40%;
      margin-bottom: 4rem;
    }
  }
`;

export const Layout1: FC<Layout1Props> = ({
  header,
  breadcrumb,
  description,
  mainContent,
}) => {
  return (
    <Layout1Div className={classes.wrapper}>
      <div
        className={classNames(
          classes.heightContainer,
          'wh-vertical-scroller-container'
        )}
      >
        <div className={classes.header}>{header}</div>
        {breadcrumb && <div className={classes.breadcrumb}>{breadcrumb}</div>}
        {description && (
          <div className={classes.description}>{description}</div>
        )}
        <div
          className={classNames(
            classes.mainContent,
            'wh-vertical-scroller-container-scroll'
          )}
        >
          {mainContent}
        </div>
      </div>
    </Layout1Div>
  );
};
