import React from 'react';

import { CustomFieldInputProps } from '../types';
import BaseCustomField from './BaseCustomField';
import DrawInput from './DrawInput';

export default function SignatureCustomField({
  ...config
}: CustomFieldInputProps) {
  return (
    <BaseCustomField {...config}>
      <DrawInput width="auto" height={250} />
    </BaseCustomField>
  );
}
