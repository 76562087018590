import React, { useState } from 'react';
import { T } from '@transifex/react';
import { Button, Row } from 'antd';
import styled from 'styled-components';

import { Typography } from 'src/ui';
import { gray200, gray300, gray800 } from 'src/theme/colors';
import DrawCanvas, { TDrawCanvasProps } from './DrawCanvas';

const Wrapper = styled.div<{ $isEdit?: boolean }>`
  padding: ${({ $isEdit }) => ($isEdit ? 0 : 16)}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border: 2px solid ${gray300};
  border-radius: 4px;

  &:hover {
    background-color: ${({ $isEdit }) => ($isEdit ? '#ffffff' : gray200)};
  }
`;

export type TDrawInputProps = Pick<TDrawCanvasProps, 'width' | 'height'> & {
  value?: string;
  disabled?: boolean;
  onChange?: (color: string) => void;
};

export default function DrawInput({
  value,
  disabled,
  width,
  height,
  onChange,
}: TDrawInputProps) {
  const [isEdit, setEdit] = useState(false);

  return (
    <Wrapper $isEdit={isEdit}>
      {isEdit && (
        <DrawCanvas
          width={width}
          height={height}
          onCancel={() => setEdit(false)}
          onChange={onChange}
        />
      )}
      {!isEdit && (
        <>
          {value && (
            <img src={value} alt="Signature" style={{ width, height }} />
          )}
          {!value && (
            <Row align="middle" justify="center" style={{ height }}>
              <Typography style={{ color: gray800 }}>
                <T _str="No image" />
              </Typography>
            </Row>
          )}
          <Button disabled={disabled} onClick={() => setEdit(true)}>
            {value ? <T _str="Edit" /> : <T _str="Add" />}
          </Button>
        </>
      )}
    </Wrapper>
  );
}
