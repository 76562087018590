export type TBorderSize = 'none' | 'small' | 'medium' | 'large';

const borderValues = {
  none: [0, 0, 0, 0, 0],
  small: [12, 8, 4, 2, 2],
  medium: [20, 16, 12, 8, 4],
  large: [32, 20, 16, 12, 8],
};

const borderVariants = ['xl', 'l', 'm', 's', 'xs'] as const;

export const generateBorderSizeValues = (borderSize: TBorderSize) => {
  return borderValues[borderSize].reduce<
    Record<(typeof borderVariants)[number], string>
  >(
    (sum, cur, index) => ({
      ...sum,
      [borderVariants[index]]: `${cur}px`,
    }),
    {
      xs: '0px',
      s: '0px',
      m: '0px',
      l: '0px',
      xl: '0px',
    }
  );
};

export const generateNumericBorderSizeValues = (borderSize: TBorderSize) => {
  return borderValues[borderSize].reduce<
    Record<(typeof borderVariants)[number], number>
  >(
    (sum, cur, index) => ({
      ...sum,
      [borderVariants[index]]: cur,
    }),
    {
      xs: 0,
      s: 0,
      m: 0,
      l: 0,
      xl: 0,
    }
  );
};
