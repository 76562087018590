import React from 'react';
import { Calendar } from 'src/antd';
import { T } from '@transifex/react';
import dayjs, { Dayjs } from 'dayjs';
import CalendarCell from 'src/components/main/routes/collections/exact/components/CalendarCell';
import { Typography } from 'src/ui/Typography';
import { StyledCalendar } from '../../../components/shared/styles/StyledCalendar';

type CollectionCalendarProps = {
  data: $TSFixMe;
  initialRange: [Dayjs, Dayjs];
  location: $TSFixMe;
};

export const BrowserlessCalendar = ({
  data,
  initialRange,
  location,
}: CollectionCalendarProps) => {
  const dateCellRender = (date: Dayjs) => {
    const listData = data[date.format('YYYY-MM-DD')] || [];

    const collectionsToShowOnCell = listData.slice(0, 1);
    const collectionsToShowOnPopup = listData.slice(1) ?? [];

    return (
      <div>
        {collectionsToShowOnCell?.map((item: $TSFixMe) => {
          return <CalendarCell onClick={() => {}} item={item} />;
        })}
        {collectionsToShowOnPopup?.length !== 0 && (
          <Typography style={{ color: '#1890FF', marginTop: 12 }}>
            <T
              _str="{count} more"
              count={`+${collectionsToShowOnPopup?.length}`}
            />
          </Typography>
        )}
      </div>
    );
  };

  return (
    <StyledCalendar>
      <div className="print-info">
        <div>
          <T _str="Address: {location}" location={location} />
        </div>
        <div>
          <T _str="Printed on: {date}" date={dayjs().format('LL')} />
        </div>
      </div>
      <div style={{ width: '100%', marginBottom: '1rem' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{ fontWeight: 800, fontSize: '1.5rem' }}>
            {dayjs(initialRange?.[0]).format('YYYY MMM')}
          </div>
        </div>
      </div>
      <Calendar
        dateCellRender={dateCellRender}
        style={{ pageBreakAfter: 'avoid', pageBreakInside: 'avoid' }}
        value={initialRange[0]}
      />
    </StyledCalendar>
  );
};
