import React from 'react';
import { T } from '@transifex/react';
import styled from 'styled-components';
import { Button, Space } from 'antd';

import { getFormattedPhoneNumber } from 'src/utils/phoneNumber';
import { Typography } from 'src/ui';

const StyledFooter = styled.footer`
  background-color: ${({ theme }) => theme.colors.dark100};
  text-align: center;
  padding: 20px 16px;
`;

const StyledLinkButton = styled(Button)`
  padding: 0;
  height: auto;
  border: none;
`;

export type IFooter = {
  companyName: string;
  phoneNumber: string;
};

const Footer = ({ companyName, phoneNumber }: IFooter): JSX.Element => {
  const formattedPhoneNumber = getFormattedPhoneNumber(phoneNumber);

  return (
    <StyledFooter>
      <Space size={32}>
        <Typography variant="h6" weight="semi-bold" style={{ color: 'white' }}>
          <T _str={companyName} />
        </Typography>
        <div>
          <StyledLinkButton type="link" href={`tel:${formattedPhoneNumber}`}>
            <Typography variant="h6" style={{ color: 'white' }}>
              <T _str={formattedPhoneNumber} />
            </Typography>
          </StyledLinkButton>
        </div>
      </Space>
    </StyledFooter>
  );
};

export default Footer;
