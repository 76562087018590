import { lowerCase } from 'lodash';
import { defaultTheme } from '@ant-design/compatible';
import React from 'react';
import { ConfigProvider } from 'antd';
import { useLocale, useT } from '@transifex/react';
import { useTheme } from 'styled-components';
import en from 'antd/lib/locale/en_US';
import { Locale } from 'antd/es/locale';
import enGB from 'antd/es/locale/en_GB';
import daDK from 'antd/es/locale/da_DK';
import { gray300 } from 'src/theme/colors';
import { TLocales } from '../transifex';

const AntdI18n: React.FC = ({ children }) => {
  const locale = useLocale();
  const theme = useTheme();

  const t = useT();

  const txOptional = `(${lowerCase(t('optional'))})`;

  const antdLocaleMap: Record<TLocales, Locale> = {
    en: enGB,
    da: {
      ...daDK,
      Form: {
        ...daDK.Form,
        optional: txOptional,
        defaultValidateMessages: {
          ...daDK.Form?.defaultValidateMessages,
        },
      },
    },
  };

  return (
    <ConfigProvider
      locale={antdLocaleMap[locale as TLocales] || en}
      theme={{
        ...defaultTheme,
        token: {
          colorPrimary: theme.colors.dark100,
          borderRadius: parseInt(theme.borders.xs, 10),
          borderRadiusSM: parseInt(theme.borders.xs, 10),
          fontFamily: `${theme.fonts.secondary}, Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`,
        },
        hashed: false,
        components: {
          ...defaultTheme.components,
          Button: {
            colorPrimary: theme.colors.dark100,
            colorPrimaryHover: theme.colors.dark80,
            colorPrimaryActive: theme.colors.dark100,
            colorBgContainerDisabled: gray300,
            borderRadius: parseInt(theme.borders.xs, 10),
            borderRadiusSM: parseInt(theme.borders.xs, 10),
            borderRadiusLG: parseInt(theme.borders.xs, 10),
            borderRadiusXS: parseInt(theme.borders.xs, 10),
            controlHeightLG: 40,
            controlHeightSM: 24,
            colorBgTextActive: theme.colors.dark5,
            defaultBorderColor: theme.colors.dark20,
            defaultActiveBorderColor: theme.colors.dark20,
            defaultHoverBorderColor: theme.colors.dark20,
            defaultHoverBg: theme.colors.dark5,
            defaultActiveBg: 'white',
            colorError: theme.colors.warning100,
            colorErrorHover: theme.colors.warning80,
            colorErrorActive: theme.colors.warning100,
            colorErrorTextActive: theme.colors.warning100,
            colorErrorBgHover: theme.colors.warning80,
            linkHoverDecoration: 'underline',
            colorLink: theme.colors.dark100,
            colorLinkHover: theme.colors.dark80,
          },
          Tabs: {
            itemColor: theme.colors.dark100,
            itemActiveColor: theme.colors.dark100,
            itemSelectedColor: theme.colors.dark100,
            inkBarColor: theme.colors.dark100,
            itemHoverColor: theme.colors.dark100,
            verticalItemPadding: '16px 8px',
            horizontalItemPadding: '16px 8px',
            horizontalItemGutter: 8,
          },
          Input: {
            activeBorderColor: theme.colors.dark100,
            activeShadow: `0 0 0 2px ${theme.colors.dark5}`,
            hoverBorderColor: theme.colors.dark100,
          },
          DatePicker: {
            activeBorderColor: theme.colors.dark100,
            activeShadow: `0 0 0 2px ${theme.colors.dark5}`,
            hoverBorderColor: theme.colors.dark100,
          },
          InputNumber: {
            activeBorderColor: theme.colors.dark100,
            activeShadow: `0 0 0 2px ${theme.colors.dark5}`,
            hoverBorderColor: theme.colors.dark100,
          },
          Steps: {
            colorPrimary: theme.colors.dark100,
            controlItemBgActive: theme.colors.light40,
            fontSizeLG: 20,
            titleLineHeight: 28,
          },
          Select: {
            colorText: theme.colors.black100,
            borderRadius: parseInt(theme.borders.xs, 10),
            borderRadiusLG: parseInt(theme.borders.s, 10),
            borderRadiusSM: parseInt(theme.borders.xs, 10),
            borderRadiusXS: parseInt(theme.borders.xs, 10),
            optionActiveBg: 'rgba(0, 0, 0, 0.06)',
            optionSelectedBg: theme.colors.light60,
            optionSelectedColor: theme.colors.dark100,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default AntdI18n;
