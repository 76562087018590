import React, { useState } from 'react';
import { useT, T } from '@transifex/react';
import { useQuery } from 'react-query';
import { Empty, Select, SelectProps } from 'src/antd';
import { useDebounce } from 'use-debounce';
import { apiClient } from 'src/api/client';

export type SelectPropertyProps = SelectProps & {
  shouldShowOptions?: boolean;
};

export const SelectProperty: React.FC<SelectPropertyProps> = ({
  onChange,
  shouldShowOptions,
  ...props
}) => {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const queryVariables = { search: debouncedSearch?.[0] };

  const { data: queryData, isFetching } = useQuery(
    [`SelectProperty`, queryVariables.search],
    () => apiClient.get('property', { params: queryVariables }),
    {
      keepPreviousData: true,
      enabled: !!queryVariables.search || shouldShowOptions,
    }
  );

  const t = useT();
  const statusPlaceholder = t('Search address');

  const onSearch = (value: string) => setSearch(value);

  const options =
    queryVariables.search || shouldShowOptions
      ? queryData?.data?.map((property: $TSFixMe) => ({
          id: property.id,
          value: property.id,
          label: property.location.name,
          data: property,
        }))
      : [];

  return (
    <Select
      className="property-select"
      showSearch
      placeholder={statusPlaceholder}
      options={options || []}
      onChange={onChange}
      notFoundContent={
        !queryVariables.search ? null : (
          <Empty
            description={<T _str="No address found" />}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )
      }
      style={{ width: '100%' }}
      searchValue={search}
      onSearch={onSearch}
      filterOption={false}
      loading={isFetching}
      {...props}
    />
  );
};
