import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  body * {}
  .wh-vertical-scroller-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    &> .wh-vertical-scroller-container-scroll {
      flex: 1;
      min-height: 0;
    }
  }

  .hoverable  {
    &:hover {
      cursor: pointer;
    }
  }

  .dark-10-hover {
    transition: opacity 0.5s cubic-bezier(0.16, 1, 0.3, 1);

    &:hover {
      opacity: 85%;
    }
  }
  
  .read-more {
    display: flex;
    width: 100%;
    justify-content: end;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s;
    transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  }

  .dark-10-hover:hover > .read-more {
    display: flex;
    max-height: 50px;
  }
`;
