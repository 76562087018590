import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Col, Empty, Row } from 'src/antd';
import { T } from '@transifex/react';
import { NO_DATA } from 'src/ui/NoData';
import { UseTreeFilterAndOrderArgs, WHTooltip } from 'src/ui';
import dayjs from 'dayjs';
import useMediaQuery from 'use-media-antd-query/es/useMediaQuery';
// eslint-disable-next-line import/no-cycle
import { ContainerDisplay } from '../../../../shared/ContainerDisplay';
import { TableKit } from '../../../../../ui-legacy/table-kit';
import { convertPriceToYearly } from '../../../../../utils/payment';

const ContainerWrapper = styled.div`
  .header-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #8c8c8c;
    margin-bottom: 1rem;
  }

  .headers {
    display: none;
  }

  @media screen and (min-width: 992px) {
    .headers {
      width: 100%;
      display: inherit;
    }
  }
`;

const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 1rem 1rem 1rem;

  height: auto;

  /* Gray / gray-3 */

  background: #f5f5f5;
  border-radius: 5px;

  margin-bottom: 1rem;

  .text-wrapper {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #0f3f6b;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .ant-col-sm-24 {
    min-width: 100%;
  }

  .ant-col-sm-12 {
    min-width: 50%;
  }

  @media screen and (min-width: 992px) {
    padding: 1.5rem;

    .text-wrapper {
      font-size: 1.25rem;
      line-height: 1.75rem;
      padding-right: 1rem;
    }

    .mobile-content {
      display: none;
    }

    .price {
      justify-content: inherit;
      margin-right: 1rem;
    }
  }
`;
export type UIProps = {
  containers: $TSFixMe[];
  loading?: boolean;
  agreements?: $TSFixMe[];
  jsx: $TSFixMe;
  autoState: $TSFixMe;
};

export type Key =
  | 'wasteFraction'
  | 'containerType'
  | 'pickupSetting'
  | 'containerId'
  | 'weekDay'
  | 'nextEmptying'
  | 'price';

export const columns: UseTreeFilterAndOrderArgs<Key>['columns'] = {
  wasteFraction: {
    title: <T _str="Waste fraction" />,
    key: 'wasteFraction',
  },
  containerType: {
    title: <T _str="Container type" />,
    key: 'containerType',
  },
  pickupSetting: {
    title: <T _str="Pickup setting" />,
    key: 'pickupSetting',
  },
  containerId: {
    title: <T _str="Container ID" />,
    key: 'containerId',
  },
  weekDay: {
    title: <T _str="Weekday" />,
    key: 'weekDay',
  },
  nextEmptying: {
    title: <T _str="Next emptying" />,
    key: 'nextEmptying',
  },
  price: {
    title: <T _str="Price" />,
    key: 'price',
  },
};

export const containerColumnLabelMap: Record<Key, ReactNode> = {
  wasteFraction: <T _str="Waste fraction" />,
  containerType: <T _str="Container type" />,
  pickupSetting: <T _str="Pickup setting" />,
  containerId: <T _str="Container ID" />,
  weekDay: <T _str="Weekday" />,
  nextEmptying: <T _str="Next emptying" />,
  price: <T _str="Price: dk/year" />,
};

export const UI = ({
  containers,
  loading,
  agreements,
  jsx,
  autoState,
}: UIProps) => {
  const state: Key[] = ['wasteFraction', ...autoState];

  const getDistanceToPriceColumn = () => {
    if (state.includes('price')) {
      const priceLocation = state.indexOf('price');

      const numberOfColumnsToCeil = 20 % state.length;

      let count = 0;

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < priceLocation; i++) {
        if (i < numberOfColumnsToCeil) {
          count += Math.ceil(20 / state.length);
        } else {
          count += Math.floor(20 / state.length);
        }
      }

      return [
        count >= Math.ceil(20 / state.length)
          ? count - Math.ceil(20 / state.length)
          : 0,
        20 - count,
      ];
    }

    return [10, 0];
  };

  const isMobile = useMediaQuery('(max-width: 996px)');

  return (
    <ContainerWrapper>
      <div className="headers">
        <Row style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}>
          {state.map((e, index) => {
            const numberOfColumnsToCeil = 20 % state.length;

            if (index < numberOfColumnsToCeil) {
              return (
                <Col lg={Math.ceil(20 / state.length)} sm={0}>
                  <div className="header-text">
                    {containerColumnLabelMap[e]}
                  </div>
                </Col>
              );
            }

            return (
              <Col lg={Math.floor(20 / state.length)} sm={0}>
                <div className="header-text">{containerColumnLabelMap[e]}</div>
              </Col>
            );
          })}
          <Col lg={state.length > 0 ? 4 : 24} sm={isMobile ? 24 : 0}>
            <div
              style={{ width: '100%', display: 'flex', justifyContent: 'end' }}
            >
              {jsx.toggler}
            </div>
          </Col>
        </Row>
      </div>
      {loading && <TableKit.Spinner />}
      {!loading && !containers.length && !agreements?.length && (
        <TableKit.NoData />
      )}
      {!state.length && (
        <Empty
          description={
            <T _str="Please select at least one column to display" />
          }
        />
      )}
      {!loading &&
        state.length > 0 &&
        containers.map((container) => (
          <ContainerDisplay
            wasteFraction={container?.container?.waste_fraction}
            containerType={
              container?.container?.container_type?.name || NO_DATA
            }
            pickupSetting={
              container?.container?.pickup_setting?.name || NO_DATA
            }
            containerId={container?.container?.container_id || NO_DATA}
            id={container?.container?.id}
            tickets={container?.num_open_tickets}
            ticketTypes={container?.ticket_types}
            price={
              convertPriceToYearly({
                recurringInterval:
                  container?.active_agreement?.price?.recurring_interval,
                amount: container?.active_agreement?.price?.amount,
                quantity: container?.active_agreement?.quantity,
              }) || 0
            }
            nextCollection={
              container?.next_collection_date
                ? dayjs(container?.next_collection_date).format('LL')
                : ''
            }
            weekday={
              container?.next_collection_date
                ? dayjs(container?.next_collection_date).format('dddd')
                : NO_DATA
            }
            state={state}
          />
        ))}
      {agreements &&
        state?.length > 0 &&
        !loading &&
        agreements?.map((agreement: $TSFixMe) => {
          return (
            <SummaryWrapper>
              <Row style={{ width: '100%' }}>
                <Col lg={Math.ceil(20 / state.length)} sm={isMobile ? 24 : 0}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginRight: '1rem',
                      width: '100%',
                    }}
                  >
                    <WHTooltip title={agreement?.name}>
                      <div
                        className="text-wrapper"
                        style={{
                          marginRight: '1rem',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          maxWidth: '85%',
                          display: 'block',
                          float: 'left',
                        }}
                      >
                        {agreement?.name}
                      </div>
                    </WHTooltip>
                    <div className="text-wrapper">x{agreement?.quantity}</div>
                  </div>
                </Col>
                {state.includes('price') && (
                  <Col
                    lg={
                      getDistanceToPriceColumn()[0] === 0
                        ? Math.ceil(20 / state.length)
                        : getDistanceToPriceColumn()[1]
                    }
                    offset={isMobile ? 0 : getDistanceToPriceColumn()[0]}
                    /* eslint-disable-next-line no-nested-ternary */
                    sm={state?.includes('price') && isMobile ? 24 : 0}
                  >
                    <div className="text-wrapper">
                      <WHTooltip
                        title={`${agreement?.quantity} * ${
                          // eslint-disable-next-line no-unsafe-optional-chaining
                          agreement?.price?.amount / 100
                        }`}
                      >
                        {isMobile && <T _str="Price: " />}
                        {/* eslint-disable-next-line no-unsafe-optional-chaining */}
                        {convertPriceToYearly({
                          recurringInterval:
                            agreement?.price?.recurring_interval,
                          amount: agreement?.price?.amount,
                          quantity: agreement?.quantity,
                        })}
                        {isMobile && <T _str=" dk / year" />}
                      </WHTooltip>
                    </div>
                  </Col>
                )}
              </Row>
            </SummaryWrapper>
          );
        })}
      {!loading &&
        state?.includes('price') &&
        !!containers.length &&
        !!agreements?.length && (
          <SummaryWrapper>
            <Row style={{ width: '100%' }}>
              <Col lg={Math.ceil(20 / state.length)} sm={isMobile ? 12 : 0}>
                <div className="text-wrapper">
                  <T _str="Total with VAT" />
                </div>
              </Col>
              <Col
                lg={
                  getDistanceToPriceColumn()[0] === 0
                    ? 6
                    : getDistanceToPriceColumn()[1]
                }
                sm={isMobile ? 12 : 0}
                offset={isMobile ? 0 : getDistanceToPriceColumn()[0]}
              >
                <div className="price">
                  <div
                    className="text-wrapper"
                    style={{
                      justifyContent: isMobile ? 'end' : 'start',
                      width: '100%',
                      display: 'flex',
                    }}
                  >
                    {/* eslint-disable-next-line no-unsafe-optional-chaining */}
                    {[
                      ...(containers?.map(
                        (e) =>
                          convertPriceToYearly({
                            recurringInterval:
                              e?.active_agreement?.price?.recurring_interval,
                            amount: e?.active_agreement?.price?.amount,
                            quantity: e?.active_agreement?.quantity,
                          }) || 0
                      ) || []),
                      // eslint-disable-next-line no-unsafe-optional-chaining
                      ...(agreements?.map(
                        (e) =>
                          convertPriceToYearly({
                            recurringInterval: e?.price?.recurring_interval,
                            amount: e?.price?.amount,
                            quantity: e?.quantity,
                          }) || 0
                      ) || [0]),
                    ]?.reduce(
                      (prevValue, currentValue) => prevValue + currentValue,
                      0
                    ) || 0}
                    {isMobile && <T _str=" dk / year" />}
                  </div>
                </div>
              </Col>
            </Row>
          </SummaryWrapper>
        )}
    </ContainerWrapper>
  );
};
