import styled from 'styled-components';

export const StepsStyles = styled.div`
  display: none;

  .ant-steps-item-title {
    margin-bottom: 2rem;
  }

  @media screen and (min-width: 992px) {
    display: inherit;
  }
`;
