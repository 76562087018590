import React, { ReactNode, useMemo } from 'react';
import { ThemeProvider, DefaultTheme } from 'styled-components';
import { Helmet } from 'react-helmet';

import { generateBorderSizeValues } from 'src/theme/borders';
import {
  alert,
  black,
  dark,
  light,
  link,
  success,
  warning,
  white,
  getColorShades,
} from 'src/theme/colors';
import { useCompany } from './Company.store';

export const ThemeStoreProvider = ({ children }: { children: ReactNode }) => {
  const { data: company } = useCompany();

  const theme = useMemo<DefaultTheme>(
    () => ({
      colors: {
        ...getColorShades('dark', company.primary_color || dark),
        ...getColorShades('light', company.secondary_color || light),
        ...getColorShades('white', white),
        ...getColorShades('black', black),
        ...getColorShades('alert', alert),
        ...getColorShades('success', success),
        ...getColorShades('warning', warning),
        ...getColorShades('link', link),
      },
      borders: generateBorderSizeValues(company.border_radius || 'medium'),
      fonts: {
        primary: company.main_portal_font
          ? 'CustomPrimaryFont'
          : company.main_portal_font_family || 'Poppins',
        secondary: company.secondary_portal_font
          ? 'CustomSecondaryFont'
          : company.secondary_portal_font_family || 'Poppins',
      },
    }),
    [company]
  );

  return (
    <>
      <Helmet
        style={[
          {
            cssText: `${
              company.main_portal_font
                ? `
                  @font-face {
                    font-family: 'CustomPrimaryFont';
                    src: url("${company.main_portal_font}");
                  }
                `
                : ''
            }\n${
              company.secondary_portal_font
                ? `
                  @font-face {
                    font-family: 'CustomSecondaryFont';
                    src: url("${company.secondary_portal_font}");
                  }
                `
                : ''
            }`,
          },
        ]}
      />
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </>
  );
};
