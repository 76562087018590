import { LeftOutlined } from '@ant-design/icons';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { T } from '@transifex/react';
import { Row } from 'antd';
import { Typography } from 'src/ui';

export type BreadcrumbProps = {
  title: ReactNode;
  suffix?: ReactNode;
  onBack?: () => void;
};

type WrapperProps = {
  $onBack: boolean;
};

const Wrapper = styled(Row)<WrapperProps>`
  gap: 8px;
  ${({ $onBack }) => $onBack && 'cursor: pointer;'}
`;

const BackIcon = styled(LeftOutlined)`
  font-size: 20px;
`;

export const BreadCrumb: FC<BreadcrumbProps> = ({ title, suffix, onBack }) => {
  return (
    <Wrapper align="middle" $onBack={!!onBack} onClick={onBack}>
      {onBack && <BackIcon />}
      <Typography variant="h4" weight="semi-bold">
        {typeof title === 'string' ? <T _str="{title}" title={title} /> : title}
      </Typography>
      {suffix}
    </Wrapper>
  );
};
