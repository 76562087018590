import { T } from '@transifex/react';
import { Button, Col, Modal, Steps } from 'src/antd';
import React, { FC, ReactNode, useContext, useEffect, useMemo } from 'react';
import { getFormattedPhoneNumber } from 'src/utils/phoneNumber';
import { useHistory } from 'react-router';
import { PropertyContext } from '../../../../context/property-context';
import { Layout1, Layout2 } from '../../../layout';
// eslint-disable-next-line import/no-cycle
import FormGetVerificationId from './components/FormGetVerificationId';
// eslint-disable-next-line import/no-cycle
import FormSelectContianer from './components/FormSelectContianer';
// eslint-disable-next-line import/no-cycle
import { Success } from './components/Success';
// eslint-disable-next-line import/no-cycle
import { Summary } from './components/Summary';
import { useSmsServiceStore, TViewStep, TNotificationMode } from './store';

import { StepsStyles } from './styles';
import { BreadCrumb } from '../../../layout/breadcrumb';
import { Header } from '../../../layout/header';
import { StepProgression } from '../../../shared';
import { getCookie, deleteCookie } from '../../../../utils/cookies';
import {
  emailCodeCookie,
  smsCodeCookie,
} from '../../../../consts/cookie-consts';
// eslint-disable-next-line import/no-cycle
import { routingMap } from '../../../../routing/routing-map';
// eslint-disable-next-line import/no-cycle
import { useGetToken } from '../../../../api/config';

const header = <T _str="Notifications" />;

const stepContentMap: Record<TViewStep, FC> = {
  '0': FormGetVerificationId,
  '1': FormSelectContianer,
  '2': Summary,
};

type NotificationFlowProps = {
  mode: TNotificationMode;
};

const NotificationFlow = ({ mode }: NotificationFlowProps) => {
  const history = useHistory();
  const token = useGetToken();
  const { id: propertyId } = useContext(PropertyContext);

  const init = useSmsServiceStore((store) => store.init);
  const step = useSmsServiceStore((store) => store.step);
  const done = useSmsServiceStore((store) => store.done);

  useEffect(() => {
    if (!init) {
      useSmsServiceStore
        .getState()
        .initStore({ context: { propertyId, token } }, mode);
    }
  }, [init, propertyId, token, mode]);

  useEffect(() => {
    return () => {
      useSmsServiceStore.getState().reset();
    };
  }, []);

  const disabledStepState = useMemo<Partial<Record<TViewStep, boolean>>>(
    () => ({
      [TViewStep.GET_VERIFICATION_CODE]: step > TViewStep.GET_VERIFICATION_CODE,
      [TViewStep.SELECT_CONTAINERS]: step === TViewStep.GET_VERIFICATION_CODE,
      [TViewStep.SUMMARY]: step !== TViewStep.SUMMARY,
    }),
    [step]
  );

  if (!init) return null;

  const StepContent = stepContentMap[step];

  if (!done) {
    return (
      <Layout1 mainContent={<Success />} header={<Header title={header} />} />
    );
  }

  const onBack = () => {
    if (step > 1) {
      useSmsServiceStore.getState().prevStep();
    } else if (
      (mode === 'sms' && getCookie(smsCodeCookie)) ||
      (mode === 'email' && getCookie(emailCodeCookie))
    ) {
      history.push(routingMap.app.notifications._);
    } else if (step > 0) {
      useSmsServiceStore.getState().prevStep();
    } else {
      history.goBack();
    }
  };

  const stepLabelMap: Record<TViewStep, ReactNode> = {
    '0':
      mode === 'sms' ? (
        <T _str="SMS notifications" />
      ) : (
        <T _str="Email notifications" />
      ),
    '1': (
      <T
        _str="Manage Notifications: {destination}"
        destination={getFormattedPhoneNumber(
          useSmsServiceStore.getState().data.other.phoneNumber || ''
        )}
      />
    ),
    '2': <T _str="Confirmation" />,
  };

  const onChangeClick = () => {
    Modal.confirm({
      title:
        mode === 'sms' ? (
          <T _str="Are you sure you want to change number" />
        ) : (
          <T _str="Are you sure you want to change email" />
        ),
      onOk: () => {
        if (mode === 'sms') {
          deleteCookie(smsCodeCookie);
        } else {
          deleteCookie(emailCodeCookie);
        }
        useSmsServiceStore.setState({
          step: TViewStep.GET_VERIFICATION_CODE,
        });
      },
      mask: true,
      maskStyle: {
        background: 'white',
      },
    });
  };

  return (
    <Layout2
      mainContentStyles={{
        overflow: 'unset',
      }}
      sideContent={
        <Col span={24}>
          <StepsStyles>
            <Steps
              onChange={(v) =>
                useSmsServiceStore.setState({ step: v as TViewStep })
              }
              direction="vertical"
              current={step}
              status={step < 3 ? 'process' : 'finish'}
            >
              {(Object.keys(stepLabelMap) as unknown as TViewStep[]).map(
                (k) => (
                  <Steps.Step
                    title={stepLabelMap[k as TViewStep]}
                    disabled={disabledStepState[k as TViewStep]}
                  />
                )
              )}
            </Steps>
          </StepsStyles>
        </Col>
      }
      breadcrumb={
        <BreadCrumb
          onBack={onBack}
          title={stepLabelMap[step]}
          suffix={
            step > 0 && (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  onChangeClick();
                }}
              >
                {mode === 'sms' ? (
                  <T _str="Change number" />
                ) : (
                  <T _str="Change email" />
                )}
              </Button>
            )
          }
        />
      }
      header={
        <Header
          title={header}
          stepProgression={
            <StepProgression currentStep={step + 1} totalSteps={3} />
          }
        />
      }
      mainContent={<StepContent />}
    />
  );
};

export default NotificationFlow;
