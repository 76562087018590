import { Calendar, Popover } from 'src/antd';
import { T } from '@transifex/react';
import React, { SetStateAction, useContext } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Typography } from 'src/ui/Typography';
import styled from 'styled-components';
import { apiHelperCollections } from 'src/api/helpers/collection';
import {
  getMonthIndex,
  getMonthRangeArray,
  getYear,
} from '../../../../../../utils';
import { PropertyContext } from '../../../../../../context/property-context';
import { StyledCalendar } from '../../../../../shared/styles/StyledCalendar';
import CalendarCell from './CalendarCell';
import useViewTicketsModal from './useViewTicketsModal';

type CollectionCalendarProps = {
  value: Dayjs;
  setValue: React.Dispatch<SetStateAction<Dayjs>>;
  data: $TSFixMe;
  onChange: (range: [Dayjs, Dayjs]) => void;
};

const StyledPopoverContainer = styled.div`
  & .ant-popover-inner-content {
    padding: 0px !important;
  }
`;

export const CollectionCalendar = ({
  value,
  setValue,
  data,
  onChange,
}: CollectionCalendarProps) => {
  const { location } = useContext(PropertyContext);

  const {
    jsx: { modal: jsxModal },
    utils: { openModal, setState: setModalState },
  } = useViewTicketsModal();

  const onSelect = (newValue: Dayjs) => {
    setValue(newValue);
  };

  const dateCellRender = (date: Dayjs) => {
    const listData = data[date.format('YYYY-MM-DD')] || [];

    const collectionsToShowOnCell = listData.slice(0, 1);
    const collectionsToShowOnPopup = listData.slice(1) ?? [];

    const onCalendarCellClick = (item: $TSFixMe) => {
      const status = apiHelperCollections.getStatus(item);
      const hasTickets = apiHelperCollections.hasTickets(item);
      if (hasTickets) {
        setModalState({
          tickets: item?.pickup_order?.created_tickets,
          collectionStatus: status,
        });
        openModal();
      }
    };

    return (
      <div>
        {collectionsToShowOnCell?.map((item: $TSFixMe) => {
          return (
            <CalendarCell
              onClick={() => {
                onCalendarCellClick(item);
              }}
              item={item}
            />
          );
        })}
        {collectionsToShowOnPopup?.length !== 0 && (
          <StyledPopoverContainer style={{ marginTop: 4 }}>
            <Popover
              showArrow={false}
              overlayClassName="whf-ant-popover no-content-padding no-arrow"
              content={
                <div
                  style={{
                    width: 215,
                    padding: 6,
                    height: 250,
                    overflow: 'scroll',
                  }}
                >
                  {collectionsToShowOnPopup?.map((item: $TSFixMe) => {
                    return (
                      <CalendarCell
                        onClick={() => {
                          onCalendarCellClick(item);
                        }}
                        item={item}
                      />
                    );
                  })}
                </div>
              }
            >
              <Typography style={{ color: '#1890FF', marginTop: 12 }}>
                <T
                  _str="{count} more"
                  count={`+${collectionsToShowOnPopup?.length}`}
                />
              </Typography>
            </Popover>
          </StyledPopoverContainer>
        )}
      </div>
    );
  };

  const onPanelChange = (e: Dayjs) => {
    onChange(getMonthRangeArray(getMonthIndex(e), Number(getYear(e))));
  };

  return (
    <StyledCalendar>
      <div className="print-info">
        <div>
          <T _str="Address: {location}" location={location?.name} />
        </div>
        <div>
          <T _str="Printed on: {date}" date={dayjs().format('LL')} />
        </div>
      </div>
      <Calendar
        dateCellRender={dateCellRender}
        value={value}
        onSelect={onSelect}
        onPanelChange={onPanelChange}
        style={{ pageBreakAfter: 'avoid', pageBreakInside: 'avoid' }}
      />
      {jsxModal}
    </StyledCalendar>
  );
};
