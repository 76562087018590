import { T } from '@transifex/react';
import { Typography } from 'src/ui/Typography';
import { Col, Row, Tabs } from 'src/antd';
import { useGetToken } from 'src/api/config';
import { TAllowedArcValue } from 'src/api/consts/CriptioArcValues';
import { TCompanyStore, useCompany } from 'src/app/Company.store';
import React, {
  CSSProperties,
  ComponentProps,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import styled from 'styled-components';
import useMediaQuery from 'use-media-antd-query/es/useMediaQuery';

import MitIDIcon from './MitID_logo.svg';
import NemIDIcon from './NemId_logo.svg';

const { TabPane } = Tabs;

const MitLoginBtn = styled.button`
  background: #0060e6;
  color: ${(props) => props.theme.colors.white100};
  font-family: 'IBM Plex Sans', inherit;
  border-radius: 4px;
  border: none;
  height: 48px;
  display: flex;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.75rem 0.25rem 1rem;
  cursor: pointer;
  &:hover {
    background-color: #004cb8;
  }
`;

const NemLoginBtn = styled.button`
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background: ${(props) => props.theme.colors.white100};
  padding: 8px;
  cursor: pointer;
  .icon {
    margin-right: 8px;
  }
`;

const StyledTab = styled(Tabs)<{ hideTabs: boolean }>`
  width: 100%;
  .ant-tabs-nav {
    display: ${({ hideTabs }) => (hideTabs ? 'none' : 'flex')};
  }
`;

const createMitIdLink = (url: string, token: string) => {
  const cleanPathname = window.location.pathname.replace(/\/$/, '');
  return url.concat(
    `?api_key=${token}&redirect_url=${encodeURIComponent(
      `${window.location.hostname}${cleanPathname}`
    )}`
  );
};

const getLabel = (arcValue: TAllowedArcValue, business: boolean) => {
  if (arcValue === 'urn:grn:authn:dk:nemid:poces') {
    return business ? (
      <T _str="Business (Company signatories) with code card" />
    ) : (
      <T _str="Personal with code card" />
    );
  }
  if (arcValue === 'urn:grn:authn:dk:nemid:moces') {
    return business ? <T _str="Business (Employee) with code card" /> : null;
  }
  if (arcValue === 'urn:grn:authn:dk:nemid:moces:codefile') {
    return business ? <T _str="Business (Employee) with code file" /> : null;
  }
  return null;
};

const CriptioLoginUI = ({
  provider,
  url,
  onClick,
  acr_values,
  business,
}: TCompanyStore['data']['authentication_urls'][0] & {
  onClick?: () => void;
}) => {
  const token = useGetToken();

  if (provider === 'mitid') {
    return (
      <MitLoginBtn
        onClick={() => {
          onClick?.();
          const href = createMitIdLink(url, token);
          window.location.href = href;
        }}
      >
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img src={MitIDIcon} style={{ width: '50px', marginRight: '1rem' }} />
        <span>Log ind med MitID</span>
      </MitLoginBtn>
    );
  }

  // else render the nemid login buttons
  return (
    <NemLoginBtn
      onClick={() => {
        const href = createMitIdLink(url, token);
        window.location.href = href;
      }}
      style={{ fontWeight: 400, fontSize: 16, lineHeight: 2 }}
    >
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img src={NemIDIcon} style={{ width: '50px', marginRight: '8px' }} />
      <span>{getLabel(acr_values, business)}</span>
    </NemLoginBtn>
  );
};

// label map for provider and use T tag
const providerLabelMap = {
  mitid: <T _str="MitID" />,
  nemid: <T _str="NemID" />,
};

// label map for business and personal use T tag
const businessLabelMap = {
  business: <T _str="Business" />,
  personal: <T _str="Personal" />,
};

const CriptioLogin = ({
  style,
  onClick,
}: { style?: CSSProperties } & Pick<
  ComponentProps<typeof CriptioLoginUI>,
  'onClick'
>) => {
  const { data: company } = useCompany();
  const isMobile = useMediaQuery('(max-width: 996px)');

  // from the company authentication urls, create data for following shape.
  /*
 {
    mitid: {
      business: Array<AuthenticationUrl>,
      personal: Array<AuthenticationUrl>,
    },
    nemid: {
      business: Array<AuthenticationUrl>,
      personal: Array<AuthenticationUrl>,
    },
 }
*/

  const authenticationUrls = company.authentication_urls.reduce(
    (acc, v) => {
      const { provider, business } = v;
      if (provider === 'mitid') {
        acc.mitid[business ? 'business' : 'personal'].push(v);
      } else {
        acc.nemid[business ? 'business' : 'personal'].push(v);
      }
      return acc;
    },
    {
      mitid: {
        business: [],
        personal: [],
      },
      nemid: {
        business: [],
        personal: [],
      },
    } as {
      mitid: {
        business: TCompanyStore['data']['authentication_urls'];
        personal: TCompanyStore['data']['authentication_urls'];
      };
      nemid: {
        business: TCompanyStore['data']['authentication_urls'];
        personal: TCompanyStore['data']['authentication_urls'];
      };
    }
  );

  const tabKeys = Object.keys(authenticationUrls).filter((provider) => {
    return (
      authenticationUrls[provider as 'mitid' | 'nemid'].business.length > 0 ||
      authenticationUrls[provider as 'mitid' | 'nemid'].personal.length > 0
    );
  });

  return (
    <StyledTab centered style={style} hideTabs={tabKeys.length === 1}>
      {tabKeys.map((provider) => {
        const { business, personal } =
          authenticationUrls[provider as 'mitid' | 'nemid'];
        return (
          <TabPane
            tab={providerLabelMap[provider as 'mitid' | 'nemid']}
            key={provider}
          >
            <Row gutter={[16, 16]}>
              {React.Children.toArray(
                [personal, business].map((urls, i) => {
                  return (
                    <Col span={isMobile ? 24 : 12}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          rowGap: 8,
                          flexDirection: 'column',
                        }}
                      >
                        <Typography weight="semi-bold" variant="h6">
                          {businessLabelMap[i === 0 ? 'personal' : 'business']}
                        </Typography>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            rowGap: 8,
                            flexDirection: 'column',
                          }}
                        >
                          {urls.map((url) => (
                            <CriptioLoginUI
                              key={url.url}
                              {...url}
                              onClick={onClick}
                            />
                          ))}
                        </div>
                      </div>
                    </Col>
                  );
                })
              )}
            </Row>
          </TabPane>
        );
      })}
    </StyledTab>
  );
};

const useCriptioLogin = () => {
  const { data: company } = useCompany();
  const token = useGetToken();
  const [jsx, setJsx] = useState<ReactNode>(null);

  const cancelMitIdSelection = () => {
    localStorage.setItem('AUTH_PROPERTY_ID', '');
    setJsx(null);
  };

  const redirectToMitId = useCallback(() => {
    if (company.authentication_urls.length === 1) {
      window.location.href = createMitIdLink(
        company.authentication_urls[0].url,
        token
      );
    } else {
      setJsx(<CriptioLogin />);
    }
  }, [company.authentication_urls, token]);

  return {
    jsx,
    redirectToMitId,
    cancelMitIdSelection,
  };
};

CriptioLogin.useCriptioLogin = useCriptioLogin;

export { CriptioLogin };
