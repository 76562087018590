import { getColorShades, warning } from 'src/theme/colors';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  .whf-ant-popover {
    &&& {
      &.no-arrow {
        padding: 0 !important;
      }
      &.no-content-padding {
      .ant-popover-inner-content {
        padding: 0px
      }
    }
    }
  }

  .ant-btn-default.ant-btn-dangerous:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: ${getColorShades('warning', warning).warning10};
  }
  .ant-btn-default,
  .ant-btn-text {
    &.ant-btn-dangerous:not(:disabled):not(.ant-btn-disabled):active {
      background-color: ${getColorShades('warning', warning).warning20};
    }
  }

  [class^="ant-tabs"], [class*=" ant-tabs"] {
    font-size: 16px;
    font-weight: 600;
  }

  .ant-tabs.ant-tabs-top .ant-tabs-tab {
    border-top-left-radius: ${({ theme }) => theme.borders.xs};
    border-top-right-radius: ${({ theme }) => theme.borders.xs};
  }
  .ant-tabs.ant-tabs-bottom .ant-tabs-tab {
    border-bottom-left-radius: ${({ theme }) => theme.borders.xs};
    border-bottom-right-radius: ${({ theme }) => theme.borders.xs};
  }
  .ant-tabs.ant-tabs-left .ant-tabs-tab {
    border-top-left-radius: ${({ theme }) => theme.borders.xs};
    border-bottom-left-radius: ${({ theme }) => theme.borders.xs};
  }
  .ant-tabs.ant-tabs-right .ant-tabs-tab {
    border-top-right-radius: ${({ theme }) => theme.borders.xs};
    border-bottom-right-radius: ${({ theme }) => theme.borders.xs};
  }
  .ant-tabs .ant-tabs-tab {
    transition: background-color 0.3s;
    &:hover {
      background-color: ${({ theme }) => theme.colors.dark5};
    }

    &.ant-tabs-tab-active .ant-tabs-tab-btn {
      text-shadow: none;
    }
    &.ant-tabs-tab-disabled {
      color: ${({ theme }) => theme.colors.dark20};
    }
    &:not(.ant-tabs-tab-disabled) {
      color: ${({ theme }) => theme.colors.dark100};
    }
  }

  .ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
    border-color: ${({ theme }) => theme.colors.dark100};
  }

  .ant-select-focused .ant-select-selector {
    box-shadow: ${({ theme }) => `0 0 0 2px ${theme.colors.dark5}`} !important;
    border-color: ${({ theme }) => theme.colors.dark100} !important;
  }
`;
