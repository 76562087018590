import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Button } from 'src/antd';
import { T } from '@transifex/react';
import createStore from 'zustand';
import styled from 'styled-components';
import { PropertyProvider } from 'src/context/property-context';
import { LayoutLogin } from 'src/layouts/Public.Auht';
import { SelectProperty } from 'src/components/SelectProperty';
import { Typography } from 'src/ui/Typography';
import { apiClient } from 'src/api/client';
import { useQuery } from 'react-query';
import { CriptioLogin } from 'src/components/CriptioLogin';
import { useCompany } from './Company.store';
import { LoadingPage } from '../components/shared';
import { useUser } from './User.store';
// import { useMitId } from './useMitId';

const StyledAlert = styled.div`
  width: 100%;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background-color: ${({ theme }) => theme.colors.light20};
  border-radius: ${({ theme }) => theme.borders.m};
`;

export type TValue = {
  id: string;
  type: {
    id: string;
    name: string;
  };
  location: {
    latitude: number;
    longitude: number;
    name: string;
  };
  ticket_types: [
    {
      id: string;
      name: string;
      service: {
        id: string;
        name: string;
        service_type: string;
      };
      is_container_level: true;
      is_property_level: true;
      portal_require_contact_information: true;
      portal_description: string;
      portal_date_label: string;
      portal_date_help_text: string;
      portal_date_required: true;
      portal_date_hidden: true;
      portal_note_label: string;
      portal_note_help_text: string;
      portal_note_required: true;
      portal_note_hidden: true;
      portal_price_show_zero: true;
      portal_contact_information_name_required: true;
      portal_contact_information_phone_number_required: true;
      portal_contact_information_email_required: true;
      portal_contact_information_next_button_label: string;
      portal_confirm_title: string;
      portal_confirm_subtitle: string;
      portal_service_type_bulk_waste_limit: number;
      portal_service_type_bulk_waste_limit_label: string;
      portal_service_type_bulk_waste_limit_help_text: string;
      portal_dropdown_hidden: true;
      portal_dropdown_required: true;
      portal_dropdown_label: string;
      portal_dropdown_help_text: string;
      portal_dropdown_options: [string];
    }
  ];
};

type TStore = {
  data: TValue;
  computed: {
    ticketTypesMap: Record<string, TValue['ticket_types'][0]>;
  };
};

const usePropertyStore = createStore<TStore>(() => ({} as TStore));

export const useProperty = () => {
  return usePropertyStore();
};

/*
1. Get propertyId

- get property id from the localStorage. if not set then go to the property selection page.
- if we get the id from selection page then set it on the localStorage.

2. Once we have the property id we need to check if anything else is required

- If LOGIN_METHOD_NONE:
  - set property and OK.
- If LOGIN_METHOD_MIDID_POST_SEARCH:
  - set property and OK.
- If LOGIN_METHOD_MIDID_PRE_SEARCH:
  - If CheckIfPropertyPropertyIsAnanymous is true then redirect to mid id.
  - else set proerty and OK.

*/

export const PropertyStoreProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const [selectPropertyJsx, setSelectPropertyJsx] =
    useState<JSX.Element | null>(null);
  const [notAllowed, setNotAllowed] = useState(false);
  const { data: company } = useCompany();
  const {
    jsx: jsxCriptioLogin,
    redirectToMitId,
    cancelMitIdSelection,
  } = CriptioLogin.useCriptioLogin();
  const { data: user, logout } = useUser();
  const property = localStorage.getItem('AUTH_PROPERTY_NAME');

  useEffect(() => {
    if (
      company.login_method === 'user' &&
      company.login_method_user === 'pre_auth_search' &&
      user.anonymous
    ) {
      // if the user is pre_auth_search and still ananoyms then reset the token.
      localStorage.setItem('AUTH_PROPERTY_ID', '');
    }
  }, [company.login_method, company.login_method_user, user.anonymous]);

  const { isLoading, refetch, isRefetching } = useQuery(
    ['PropertyStoreProvider', 'Get'],
    async () => {
      setNotAllowed(false); // reseting the state when get's refetch again
      let propertyId = localStorage.getItem('AUTH_PROPERTY_ID');

      if (!propertyId) {
        propertyId = await new Promise<string>((res) => {
          const jsx = (
            <>
              <SelectProperty
                size="large"
                style={{ width: '100%', maxWidth: 600 }}
                shouldShowOptions={
                  company.login_method_user === 'post_auth_search'
                }
                onChange={(v, option: $TSFixMe) => {
                  localStorage.setItem('AUTH_PROPERTY_NAME', option.label);
                  if (
                    company.login_method === 'user' &&
                    company.login_method_user === 'pre_auth_search' &&
                    user.anonymous
                  ) {
                    localStorage.setItem('AUTH_PROPERTY_ID', v as string);
                    localStorage.setItem(
                      'AUTH_TOKEN_EXPIRY',
                      dayjs().add(60, 'minutes').toISOString()
                    );
                    redirectToMitId();
                  } else {
                    res(v as string);
                  }
                }}
              />
              {company.login_method_user === 'post_auth_search' && (
                <Button type="text" onClick={logout}>
                  <T _str="Login with another account" />
                </Button>
              )}
            </>
          );
          setSelectPropertyJsx(jsx);
        }).then((v) => {
          localStorage.setItem('AUTH_PROPERTY_ID', v as string);
          localStorage.setItem(
            'AUTH_TOKEN_EXPIRY',
            dayjs().add(60, 'minutes').toISOString()
          );
          setSelectPropertyJsx(null);
          return v;
        });
      }

      // if there is no token then fetch it using company login method.
      return apiClient
        .get(`property/${propertyId}`)
        .then((d) => {
          if (d.data.id) {
            const p = d.data as TValue;
            usePropertyStore.setState({
              data: d.data,
              computed: {
                ticketTypesMap: p.ticket_types.reduce(
                  (acc, t) => ({ ...acc, [t.id]: t }),
                  {}
                ),
              },
            });
          }
        })
        .catch((e) => {
          if (e.response.status === 404) {
            // wrong property selected. Not authorized.
            setNotAllowed(true);
          }
        });
    }
  );

  if (jsxCriptioLogin)
    return (
      <LayoutLogin title={property} withIcon>
        <Button onClick={cancelMitIdSelection}>
          <T _str="Change to another address" />
        </Button>
        {jsxCriptioLogin}
      </LayoutLogin>
    );
  if (selectPropertyJsx)
    return (
      <LayoutLogin
        title={<T _str="Enter your address" />}
        description={<T _str="Enter address below to login to your account" />}
        withIcon
      >
        {selectPropertyJsx}
      </LayoutLogin>
    );

  if (isLoading || isRefetching) {
    return <LoadingPage />;
  }

  if (notAllowed) {
    return (
      <LayoutLogin>
        <StyledAlert>
          <Typography variant="h4" weight="semi-bold">
            <T _str="No Access" />
          </Typography>
          <Typography ellipsis={false} style={{ textAlign: 'center' }}>
            <T
              _str="You do not have access to visit this property. Please try another address or login again. Your currently chose: {address}"
              address={<b>{property}</b>}
            />
          </Typography>
          <Button
            onClick={() => {
              localStorage.setItem('AUTH_PROPERTY_ID', '');
              refetch();
            }}
          >
            <T _str="Change to another address" />
          </Button>
        </StyledAlert>
        <CriptioLogin
          style={{ margin: 'auto' }}
          onClick={() => {
            localStorage.setItem('AUTH_TOKEN', '');
            localStorage.setItem('AUTH_PROPERTY_ID', '');
          }}
        />
      </LayoutLogin>
    );
  }

  return <PropertyProvider>{children}</PropertyProvider>;
};
