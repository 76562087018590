import React, { useContext, useState } from 'react';
import { T, useT, useTX } from '@transifex/react';
import { useQuery } from 'react-query';
import { Button } from 'src/antd';
import download from 'downloadjs';
import dayjs from 'dayjs';
import { SelectYearSimple } from 'src/ui/SelectMoment/SelectYearSimple';
import { SelectMonthSimple } from 'src/ui/SelectMoment/SelectMonthSimple';
import { Typography } from 'src/ui/Typography';
// eslint-disable-next-line import/no-cycle
import { useGetToken } from '../../../../../api/config';
import { PropertyContext } from '../../../../../context/property-context';
import { formatDate } from '../../../../../utils';
import { GetCalendar } from './api';
import GeneratingCalendar from './generating';
// eslint-disable-next-line import/no-cycle
import CompleteCalendar from './complete';
// eslint-disable-next-line import/no-cycle
import { useRouting } from '../../../../../routing/child-router-factory';

const DownloadCalendar = () => {
  const token = useGetToken();
  const { id: propertyId, location } = useContext(PropertyContext);

  const [fromYear, setFromYear] = useState(dayjs().year());
  const [toYear, setToYear] = useState(dayjs().year());
  const [toMonth, setToMonth] = useState(dayjs().month());
  const [fromMonth, setFromMonth] = useState(dayjs().month());
  const [pdfData, setPdfData] = useState();

  const history = useRouting.useHistory();

  const t = useT();
  const tx = useTX();
  const cancelText = t('Cancel');
  const downloadText = t('Download');
  const collectionsString = t('Collections');

  const queryParameters = `api_key,${token}|from_date,${dayjs(
    `${fromYear}-${fromMonth + 1}-01`
  ).format('YYYY-MM-DD')}|to_date,${dayjs(`${toYear}-${toMonth + 1}-01`)
    .endOf('month')
    .format(
      'YYYY-MM-DD'
    )}|property_id,${propertyId}|language,${tx.getCurrentLocale()}`;

  const filename = `${collectionsString}_${location?.name}_${formatDate(
    dayjs(dayjs(`${fromYear}-01-01`).month(fromMonth))
  )}_${formatDate(dayjs())}.pdf`;

  const {
    data,
    refetch,
    isLoading: loading,
    isFetching: fetching,
  } = useQuery(
    ['get-collection-calendar', fromYear, fromMonth, token, queryParameters],
    () => GetCalendar(token, filename, `calendar`, queryParameters, false),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (response) => {
        setPdfData(response.data);
        const content = response.headers['content-type'];
        download(response.data, filename, content);
      },
    }
  );

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
    >
      {!loading && !fetching && !pdfData && (
        <>
          <div
            style={{
              fontSize: '1.25rem',
              fontWeight: 600,
              marginBottom: '4rem',
            }}
          >
            <T _str="Please select date" />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: '2rem',
            }}
          >
            <div>
              <Typography weight="semi-bold">
                <T _str="From" />
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '3rem',
                  marginTop: '0.5rem',
                }}
              >
                <div style={{ minWidth: '200px' }}>
                  <SelectYearSimple
                    value={fromYear}
                    onChange={(e) => setFromYear(e)}
                    style={{ marginRight: '1rem', width: 200 }}
                    validRange={[2018, 2030]}
                  />
                </div>
                <div style={{ minWidth: '200px' }}>
                  <SelectMonthSimple
                    onChange={(e) => setFromMonth(e)}
                    value={fromMonth}
                    style={{ marginRight: '1rem', width: 200 }}
                  />
                </div>
              </div>
            </div>
            <div>
              <Typography weight="semi-bold">
                <T _str="To" />
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '3rem',
                  marginTop: '0.5rem',
                }}
              >
                <div style={{ minWidth: '200px' }}>
                  <SelectYearSimple
                    value={toYear}
                    onChange={(e) => setToYear(e)}
                    style={{ marginRight: '1rem', width: 200 }}
                    validRange={[2018, 2030]}
                  />
                </div>
                <div style={{ minWidth: '200px' }}>
                  <SelectMonthSimple
                    onChange={(e) => setToMonth(e)}
                    value={toMonth}
                    style={{ marginRight: '1rem', width: 200 }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              style={{ marginRight: '1rem' }}
              onClick={() => history.goBack()}
            >
              {cancelText}
            </Button>
            <Button type="primary" onClick={() => refetch()}>
              {downloadText}
            </Button>
          </div>
        </>
      )}
      {(loading || fetching) && <GeneratingCalendar />}
      {pdfData && (
        <CompleteCalendar
          data={data}
          setPdfData={setPdfData}
          filename={filename}
        />
      )}
    </div>
  );
};

export default DownloadCalendar;
