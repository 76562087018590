import React from 'react';
import { T } from '@transifex/react';
import { Modal } from 'src/antd';
import { useUserStore } from 'src/app/User.store';
import axios, { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { getOverrideApiUrl } from './state';

let showSessionTimeoutModal = false;

export const baseURL = getOverrideApiUrl() || process.env.REACT_APP_API_URL;

axios.defaults.baseURL = baseURL;

axios.interceptors.request.use(function automaticLogoutSessionHandler(config) {
  if (window.usingBrowerserLess) return config;
  //  check for token validation here.
  if (config.headers?.['X-API-Key']) {
    //  then it's means it an authenticated token request.
    const tokenExpiry = localStorage.getItem('AUTH_TOKEN_EXPIRY');
    if (
      !tokenExpiry ||
      (dayjs().isAfter(dayjs(tokenExpiry)) && !showSessionTimeoutModal)
    ) {
      //  then it means that the token is expired so logout.

      Modal.warn({
        title: <T _str="Session timeout. Login again" />,
        onOk: useUserStore.getState().logout,
        mask: true,
        maskStyle: {
          background: 'white',
        },
      });
      showSessionTimeoutModal = true;
    }
  }

  return config;
});

axios.interceptors.response.use(undefined, (error: AxiosError) => {
  const body = error?.response?.data?.body;
  if (body) {
    Modal.error({
      title: <T _str="An error occured" />,
      content: body,
    });
  }
  return Promise.reject(error);
});

// On apiClient we don't set the automaticLogoutSessionHandler Interceptor because it will not be used inside app rather by auth layer only.

const apiClient = axios.create({ baseURL });

const setToken = (token: string) => {
  apiClient.defaults.headers.common['X-API-Key'] = token;
};

const apiClientMethods = { setToken };

export { apiClient, apiClientMethods };

export const APIURLS = {
  production: process.env.REACT_APP_API_PRODUCTION_URL || 'not-set',
  staging: process.env.REACT_APP_API_STAGING_URL || 'not-set',
  development: process.env.REACT_APP_API_DEVELOPMENT_URL || 'not-set',
  preview: process.env.REACT_APP_API_PREVIEW_URL || 'not-set',
};
