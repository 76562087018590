import React, { ReactNode } from 'react';
import { EnvironmentOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Typography } from 'src/ui';

export type LoginContainerProps = {
  title?: ReactNode;
  description?: ReactNode;
  withIcon?: boolean;
  children?: ReactNode;
};

const LoginContainerDiv = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  background: white;

  @media screen and (min-width: 1200px) {
    padding: 60px 40px;
  }
`;

const Icon = styled(EnvironmentOutlined)`
  font-size: 60px;
  color: ${({ theme }) => theme.colors.dark20};
`;

export const LoginContainer: React.FC<LoginContainerProps> = ({
  title,
  description,
  withIcon,
  children,
}: LoginContainerProps) => {
  return (
    <LoginContainerDiv>
      {withIcon && <Icon />}
      {title && (
        <Typography
          variant="h4"
          weight="semi-bold"
          ellipsis={false}
          style={{ textAlign: 'center' }}
        >
          {title}
        </Typography>
      )}
      {description && (
        <Typography ellipsis={false} style={{ textAlign: 'center' }}>
          {description}
        </Typography>
      )}
      {children}
    </LoginContainerDiv>
  );
};
