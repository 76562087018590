import { T } from '@transifex/react';
import React, { useMemo, useState } from 'react';
import { Button } from 'src/antd';
import { pick } from 'lodash';
import createStore from 'zustand';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { LayoutLogin } from 'src/layouts/Public.Auht';
import { Typography } from 'src/ui/Typography';
import { CitizenProvider } from 'src/context/citizen-context';
import { apiClient, apiClientMethods } from 'src/api/client';
import { CriptioLogin } from 'src/components/CriptioLogin';
import { useQuery } from 'react-query';
import { LoadingPage } from '../components/shared';
import { useCompany } from './Company.store';

const StyledAlert = styled.div`
  width: 100%;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background-color: ${({ theme }) => theme.colors.light20};
  border-radius: ${({ theme }) => theme.borders.m};
`;

type TValue = {
  id: string;
  username: string;
  token: string;
  language: string;
  phone_number: string;
  email: string;
  name: string;
  anonymous: boolean;
};

type TStore = {
  data: TValue;
  logout: () => void;
  update: (v: Pick<TValue, 'language'>) => Promise<void>;
};

export const useUserStore = createStore<TStore>(() => ({} as TStore));

export const useUser = () => {
  return useUserStore();
};

export const useUserMaybe = (): TValue | undefined => {
  return useUserStore().data;
};

/*
- getToken: from AUTH_TOKEN or from search param or from the api.
- getUser: using the token

- If LOGIN_METHOD_NONE:
  - set property user and OK.
- If LOGIN_METHOD_MIDID_POST_SEARCH:
  - If CheckIfPropertyUserIsAnanymous is true then redirect to mit id.
  - else set property user and OK.
- If LOGIN_METHOD_MIDID_PRE_SEARCH
  - set property user and OK.

*/

export const UserStoreProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const { data: company, hostname } = useCompany();
  const { data: user } = useUser();
  const searchParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );
  const [error, setError] = useState<{
    errorCode?: string;
    errorMessage?: string;
  }>({});

  const logout = () => {
    localStorage.setItem('AUTH_TOKEN', '');
    localStorage.setItem('AUTH_PROPERTY_ID', '');
    window.history.replaceState(
      {},
      document.title,
      window.WH_SS_INIT_ROUTE || '/'
    );
    window.location.reload();
  };

  const update: TStore['update'] = async (values) => {
    await apiClient
      .post(`property_user`, {
        ...pick(user, ['name', 'phone_number', 'email', 'language']),
        ...values,
      })
      .then((d) => {
        useUserStore.setState({ data: d.data });
      });
  };

  const { isLoading } = useQuery(['UserStoreProvider', 'Get'], async () => {
    // if there is error then show error and return
    const errorCode = searchParams.get('error_code') || undefined;
    const errorMessage = searchParams.get('error') || undefined;
    if (errorCode || errorMessage) {
      setError({
        errorCode,
        errorMessage,
      });
      return null;
    }

    // get the token
    const token =
      searchParams.get('api_key') || localStorage.getItem('AUTH_TOKEN');

    if (token) {
      // need to remove the api_key from the url once we used it.
      if (searchParams.get('api_key')) {
        const propertyId = searchParams.get('property_key');
        // also checking for property key here
        if (propertyId) {
          localStorage.setItem('AUTH_PROPERTY_ID', propertyId as string);
          localStorage.setItem(
            'AUTH_TOKEN_EXPIRY',
            dayjs().add(60, 'minutes').toISOString()
          );
        }
        window.history.replaceState({}, document.title, '/');
      }

      apiClientMethods.setToken(token);
      return apiClient
        .get('property_user')
        .then((d) => {
          if (d.data.id) {
            useUserStore.setState({ data: d.data, logout, update });
            localStorage.setItem('AUTH_TOKEN', token);
          }
        })
        .catch((e) => {
          setError({
            errorMessage: e?.message || <T _str="Something went wrong" />,
          });
        });
    }
    // if there is no token then fetch it using company login method.
    return apiClient
      .post(`company/${hostname}/login`, { username: '', password: '' })
      .then((d) => {
        if (d.data.id) {
          apiClientMethods.setToken((d.data as TValue).token);
          useUserStore.setState({ data: d.data, logout, update });
          localStorage.setItem('AUTH_TOKEN', (d.data as TValue).token);
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          // wrong property selected. Not authorized. invalid token
          logout();
        }
      });
  });

  if (isLoading) {
    return <LoadingPage />;
  }

  if (user) {
    if (
      company.login_method === 'user' &&
      company.login_method_user === 'post_auth_search' &&
      user.anonymous
    ) {
      // show mit id login here.
      return (
        <LayoutLogin title={<T _str="Login" />}>
          <CriptioLogin />
        </LayoutLogin>
      );
    }
    return <CitizenProvider>{children}</CitizenProvider>;
  }

  return (
    <LayoutLogin>
      <StyledAlert>
        <Typography
          variant="h4"
          weight="semi-bold"
          ellipsis={false}
          style={{ textAlign: 'center' }}
        >
          {error.errorCode || <T _str="Error" />}
        </Typography>
        <Typography ellipsis={false} style={{ textAlign: 'center' }}>
          {error?.errorMessage || <T _str="An unknown error occured" />}
        </Typography>
        <Button onClick={logout}>
          <T _str="Back to Login" />
        </Button>
      </StyledAlert>
    </LayoutLogin>
  );
};
