import React, { FC } from 'react';
import { Typography as T } from 'antd';
import { TextProps } from 'antd/es/typography/Text';
import { TitleProps } from 'antd/es/typography/Title';
import { ParagraphProps } from 'antd/es/typography/Paragraph';
import { LinkProps } from 'antd/es/typography/Link';
import { useTheme } from 'styled-components';

import { StyledSkeletonInput } from '../Skeleton';
import {
  TVariant,
  variants,
  TFontWeight,
  fontWeights,
  loadingStylesMap,
  TLoadingSizes,
  fontFamilies,
} from './config';

export type { TFontWeight, TVariant } from './config';

const { Link, Paragraph, Text, Title } = T;

const components = {
  Text,
  Title,
  Paragraph,
  Link,
};

type TTextComponent = {
  component?: 'Text';
} & TextProps;

type TTitleComponent = {
  component?: 'Title';
} & TitleProps;

type TParagraphComponent = {
  component?: 'Paragraph';
} & ParagraphProps;

type TLinkComponent = {
  component?: 'Link';
} & LinkProps;

export type TTypographyProps = {
  variant?: TVariant;
  weight?: TFontWeight;
  loading?: boolean;
  loadingSize?: TLoadingSizes;
} & (TTextComponent | TTitleComponent | TParagraphComponent | TLinkComponent);

export const Typography: FC<TTypographyProps> = ({
  component = 'Text',
  variant = 'body-14',
  weight = 'regular',
  children,
  loading,
  loadingSize = 'default',
  ...props
}) => {
  const theme = useTheme();

  const additionalProps: TTypographyProps = {};
  if (component === 'Text' || component === 'Link') {
    additionalProps.ellipsis = {
      tooltip: children,
    };
  }

  const Component = components[component];

  if (loading) {
    return (
      <StyledSkeletonInput
        size="default"
        width={loadingStylesMap[loadingSize].width}
        style={{
          height: variants[variant].fontSize,
          lineHeight: variants[variant].lineHeight,
        }}
        active
      />
    );
  }

  return (
    <Component
      {...additionalProps}
      {...(props as $TSFixMe)}
      style={{
        fontFamily: theme.fonts[fontFamilies[variant]],
        ...variants[variant],
        ...fontWeights[weight],
        ...props.style,
      }}
    >
      {children}
    </Component>
  );
};
