import React from 'react';
import { Layout1 } from 'src/components/layout';
import { BreadCrumb } from 'src/components/layout/breadcrumb';
import { T } from '@transifex/react';
import { Header } from 'src/components/layout/header';
import {
  MailOutlined,
  MobileOutlined,
  NotificationOutlined,
} from '@ant-design/icons';
import { Button, Col, Row } from 'src/antd';
import { Typography } from 'src/ui/Typography';
import useMediaQuery from 'use-media-antd-query/es/useMediaQuery';
import { useRouting } from 'src/routing/child-router-factory';
import { useCompany } from 'src/app/Company.store';

const getContentMap = (
  routingMap: ReturnType<typeof useRouting>['routingMap']
) => ({
  sms: {
    icon: <MobileOutlined style={{ fontSize: 35, color: '#0F3F6B' }} />,
    title: <T _str="Collection notifications for SMS" />,
    description: (
      <T _str="Set up a SMS notification for your container collection here. We will send you a reminder through SMS messages." />
    ),
    route: routingMap.app.notifications.sms._,
  },
  email: {
    icon: <MailOutlined style={{ fontSize: 35, color: '#0F3F6B' }} />,
    title: <T _str="Collection notifications for Email" />,
    description: (
      <T _str="Set up a Email notification for your container collection here. We will send you a reminder through Email." />
    ),
    route: routingMap.app.notifications.email._,
  },
});

const Notifications = () => {
  const { data: companyData } = useCompany();

  const columnLayout = useMediaQuery('(max-width: 1100px)');
  const colXs = columnLayout ? 24 : undefined;
  const history = useRouting.useHistory();
  const { routingMap } = useRouting();

  const contentMap = getContentMap(routingMap);

  const content = companyData.crm_enable_container_notification_providers.map(
    (type) => contentMap[type as keyof typeof contentMap]
  );

  return (
    <Layout1
      breadcrumb={<BreadCrumb title={<T _str="Notifications" />} />}
      header={
        <Header
          icon={<NotificationOutlined />}
          title={<T _str="Notifications" />}
        />
      }
      mainContent={
        <Row style={{ paddingBottom: '2rem' }} gutter={[0, 8]}>
          {content.map(({ icon, title, description, route }) => (
            <Col xs={24}>
              <Row
                style={{
                  backgroundColor: 'rgba(15, 63, 107, 0.05)',
                  padding: '32px 24px',
                  ...(columnLayout
                    ? { padding: 12, paddingTop: 20, paddingBottom: 20 }
                    : {}),
                  borderRadius: 8,
                }}
                justify="space-between"
                wrap={columnLayout}
                gutter={[16, 16]}
              >
                <Col xs={colXs}>
                  <Row wrap={columnLayout} gutter={[24, 16]} align="middle">
                    <Col xs={colXs}>
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        {icon}
                      </div>
                    </Col>
                    <Col
                      style={{ textAlign: columnLayout ? 'center' : 'start' }}
                    >
                      <Row justify={columnLayout ? 'center' : 'start'}>
                        <Col xs={24}>
                          <Typography
                            color="primary"
                            weight="semi-bold"
                            variant="h5"
                            style={{ color: '#0F3F6B' }}
                          >
                            {title}
                          </Typography>
                        </Col>
                        <Col xs={24}>
                          <Typography
                            style={{ color: 'rgba(15, 63, 107, 0.7)' }}
                            variant="h6"
                          >
                            {description}
                          </Typography>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col
                  style={{ textAlign: columnLayout ? 'center' : 'start' }}
                  xs={colXs}
                >
                  <Row wrap={columnLayout} gutter={[8, 8]}>
                    <Col order={columnLayout ? 2 : 1} xs={colXs}>
                      <Button
                        onClick={() => {
                          history.push(route);
                        }}
                        style={{ width: '100%', maxWidth: 600 }}
                      >
                        <T _str="Manage subscription" />
                      </Button>
                    </Col>
                    <Col order={columnLayout ? 1 : 2} xs={colXs}>
                      <Button
                        onClick={() => {
                          history.push(route);
                        }}
                        style={{ width: '100%', maxWidth: 600 }}
                        type="primary"
                      >
                        <T _str="Subscribe" />
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      }
    />
  );
};

export default Notifications;
