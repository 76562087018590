import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Row, Space } from 'antd';
import { T } from '@transifex/react';
import { Typography } from 'src/ui';
import { LanguagePicker } from '../../I18n';
import ApiUrl from './ApiUrl';

export type HeaderProps = {
  title: string | ReactNode;
  icon?: ReactNode;
  stepProgression?: ReactNode;
};

const Wrapper = styled(Row)`
  width: 100%;
`;

const StyledRow = styled(Row)`
  gap: 8px;

  .anticon {
    font-size: 20px;
  }
`;

export const Header: FC<HeaderProps> = ({ title, icon, stepProgression }) => {
  return (
    <Wrapper align="middle" justify="space-between">
      <StyledRow align="middle">
        {icon}
        <Typography variant="h5" weight="semi-bold">
          {typeof title === 'string' ? (
            <T _str="{title}" title={title} />
          ) : (
            title
          )}
        </Typography>
      </StyledRow>
      <Space>
        <ApiUrl />
        <LanguagePicker />
        {stepProgression}
      </Space>
    </Wrapper>
  );
};
