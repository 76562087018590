import React, { useEffect, useRef, useState } from 'react';
import {
  CheckOutlined,
  CiCircleFilled,
  RedoOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import { T } from '@transifex/react';
import { Button, Divider, Row, Space } from 'antd';
import styled from 'styled-components';
import { Typography } from 'src/ui';
import { gray300, gray800 } from 'src/theme/colors';

const Header = styled.div`
  width: 100%;
  padding: 8px 16px;
  background-color: ${gray300};
`;

const StyledButton = styled(Button)<{ $selected?: boolean }>`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ $selected }) =>
    $selected ? gray300 : '#ffffff'} !important;

  svg {
    fill: ${({ $selected }) => ($selected ? '#ffffff' : gray300)};
  }
`;

const Footer = styled.div`
  width: 100%;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-top: 1px solid ${gray300};
`;

export type TDrawCanvasProps = {
  width: 'auto' | number;
  height: number;
  onCancel?: () => void;
  onChange?: (color: string) => void;
};

type Point = {
  x: number;
  y: number;
  size: number;
};

export default function DrawCanvas({
  width,
  height,
  onCancel,
  onChange,
}: TDrawCanvasProps) {
  const [size, setSize] = useState(4);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const pathsRef = useRef<Point[][]>([]);
  const redoPathsRef = useRef<Point[][]>([]);

  const handleClear = () => {
    if (!canvasRef.current) {
      return;
    }

    canvasRef.current
      .getContext('2d')
      ?.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
  };

  const handleSave = () => {
    if (!canvasRef.current) {
      return;
    }

    onChange?.(canvasRef.current.toDataURL());
    onCancel?.();
  };

  const drawPaths = () => {
    const ctx = canvasRef.current?.getContext('2d');
    if (!ctx) {
      return;
    }

    pathsRef.current.forEach((path) => {
      ctx.beginPath();
      ctx.moveTo(path[0].x, path[0].y);
      for (let i = 1; i < path.length; i += 1) {
        ctx.lineTo(path[i].x, path[i].y);
      }
      ctx.lineWidth = path[0].size;
      ctx.stroke();
    });
  };

  const handleUndo = () => {
    handleClear();
    redoPathsRef.current.push(...pathsRef.current.splice(-1, 1));
    drawPaths();
  };

  const handleRedo = () => {
    handleClear();
    pathsRef.current.push(...redoPathsRef.current.splice(-1, 1));
    drawPaths();
  };

  useEffect(() => {
    const parent = canvasRef.current?.parentElement;
    const ctx = canvasRef.current?.getContext('2d');
    if (!parent || !ctx || width !== 'auto') {
      return () => {};
    }

    const resize = () => {
      ctx.canvas.width = parent.offsetWidth;
    };

    resize();
    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [width]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    if (!canvas || !ctx) {
      return () => {};
    }

    let prevX = 0;
    let prevY = 0;
    let curX = 0;
    let curY = 0;
    let points: Point[] = [];

    const setPosition = (e: MouseEvent) => {
      prevX = curX;
      prevY = curY;
      curX = e.offsetX;
      curY = e.offsetY;
      points.push({ x: curX, y: curY, size });
    };

    const draw = (e: MouseEvent) => {
      setPosition(e);
      ctx.beginPath();
      ctx.lineWidth = size;
      ctx.lineCap = 'round';
      ctx.strokeStyle = '#000000';

      ctx.moveTo(prevX, prevY);
      ctx.lineTo(curX, curY);

      ctx.stroke();
    };

    const onMouseDown = (e: MouseEvent) => {
      points = [];
      setPosition(e);
      canvas.addEventListener('mousemove', draw);
    };

    const onMouseUp = () => {
      pathsRef.current.push(points);
      canvas.removeEventListener('mousemove', draw);
    };

    canvas.addEventListener('mousedown', onMouseDown);
    canvas.addEventListener('mouseup', onMouseUp);

    return () => {
      canvas.removeEventListener('mousedown', onMouseDown);
      canvas.removeEventListener('mouseup', onMouseUp);
    };
  }, [size]);

  return (
    <>
      <Header>
        <Row align="middle" style={{ gap: 8 }}>
          <Button
            type="text"
            size="small"
            icon={<UndoOutlined />}
            onClick={handleUndo}
          />
          <Button
            type="text"
            size="small"
            icon={<RedoOutlined />}
            onClick={handleRedo}
          />
          <Divider type="vertical" style={{ height: 20, margin: 0 }} />
          <Typography variant="body-12" style={{ color: gray800 }}>
            <T _str="Size" />
          </Typography>
          <Space size={4}>
            <StyledButton
              type="text"
              size="small"
              $selected={size === 2}
              style={{ marginLeft: 4 }}
              onClick={() => setSize(2)}
            >
              <CiCircleFilled size={3} />
            </StyledButton>
            <StyledButton
              type="text"
              size="small"
              $selected={size === 4}
              onClick={() => setSize(4)}
            >
              <CiCircleFilled size={6} />
            </StyledButton>
            <StyledButton
              type="text"
              size="small"
              $selected={size === 6}
              onClick={() => setSize(6)}
            >
              <CiCircleFilled size={8} />
            </StyledButton>
          </Space>
        </Row>
      </Header>
      <canvas ref={canvasRef} width={width} height={height} />
      <Footer>
        <Button type="text" danger onClick={handleClear}>
          <T _str="Clear" />
        </Button>
        <Button style={{ marginLeft: 'auto' }} onClick={onCancel}>
          <T _str="Cancel" />
        </Button>
        <Button type="primary" icon={<CheckOutlined />} onClick={handleSave}>
          <span>
            <T _str="Save" />
          </span>
        </Button>
      </Footer>
    </>
  );
}
