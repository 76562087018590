import React, { ReactNode } from 'react';
import { T, useT } from '@transifex/react';
import dayjs from 'dayjs';

export const PrefferedDate = ({
  value,
  dateText,
  dateHidden,
}: {
  value: dayjs.Dayjs;
  dateText?: string;
  dateHidden?: boolean;
}) => {
  const t = useT();

  if (dateHidden) {
    return null;
  }

  return (
    <>
      {!dateText && (
        <T
          _str="Preferred date: {value}"
          value={
            value ? dayjs(value).format('LL') : <T _str="As soon as possible" />
          }
        />
      )}
      {dateText &&
        `${dateText}: ${
          value ? dayjs(value).format('LL') : t('As soon as possible')
        }`}
    </>
  );
};

export const SummaryNote = ({
  note,
  noteLabel,
}: {
  note: string;
  noteLabel?: string;
}) => {
  return (
    <div>
      {note && !noteLabel && <T _str="Note: {value}" value={note} />}
      {note && noteLabel && `${noteLabel}: ${note}`}
    </div>
  );
};

export const ChangeFromTo = ({
  from,
  to,
}: {
  from: ReactNode;
  to: ReactNode;
}) => <T _str="Change from: {from} to {to}" from={from} to={to} />;
