import React, { useContext } from 'react';
import { Col, Grid, Row } from 'src/antd';
import { SmileOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import { T } from '@transifex/react';
import useMediaQuery from 'use-media-antd-query/es/useMediaQuery';
import { Layout1 } from '../../../../../layout';
import { BreadCrumb } from '../../../../../layout/breadcrumb';
import CustomButton from './components/ServiceButton';
import { Header } from '../../../../../layout/header';
// eslint-disable-next-line import/no-cycle
import { RouterContext } from '../../../../../../context/routing';
import { getTicketTypeIcon } from '../../../../../../api/consts/CrmServiceServiceTypeChoices';
import { PropertyContext } from '../../../../../../context/property-context';
import HomeContent from './HomeContent';

const Exact = () => {
  const { routingMap } = useContext(RouterContext);
  const { ticketTypes } = useContext(PropertyContext);
  const history = useHistory();

  const { xs } = Grid.useBreakpoint();
  const isMd = useMediaQuery('(max-width: 1200px)');

  return (
    <Layout1
      breadcrumb={<BreadCrumb title={<T _str="Self-service" />} />}
      header={
        <Header icon={<SmileOutlined />} title={<T _str="Self-service" />} />
      }
      mainContent={
        <Row
          gutter={[xs ? 16 : 64, xs ? 16 : 64]}
          style={{ height: '100%', minHeight: 480 }}
        >
          <Col xs={24} xl={12} style={{ maxHeight: '100%' }}>
            <HomeContent />
          </Col>
          <Col xs={24} xl={12} style={{ maxHeight: '100%' }}>
            <Row
              gutter={[16, 16]}
              style={{
                marginBottom: '1rem',
                maxHeight: '100%',
                overflowY: isMd ? 'unset' : 'auto',
              }}
            >
              {ticketTypes.map((ticketType: $TSFixMe) => {
                const { id, name } = ticketType;
                const [Icon, props] = getTicketTypeIcon(ticketType) || [];
                return (
                  <Col key={id} xs={24}>
                    <CustomButton
                      icon={Icon ? <Icon {...props} /> : <div />}
                      onClick={() =>
                        history.push(
                          routingMap.app.selfService['create/:ticketTypeId'](id)
                            ._
                        )
                      }
                      title={name}
                    />
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      }
    />
  );
};

export default Exact;
