import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Drawer, Row } from 'src/antd';
import { MenuOutlined } from '@ant-design/icons';
import NavigationContent from './components/navigation-content';
import UserInformation, {
  UserInformationProps,
} from './components/information';
import NavigationBarButtonControls, {
  NavigationBarButtonControlsProps,
} from './components/button-controls';
import MenuTree, { MenuTreeProps } from './components/menu-tree';
import { createPrefixer } from '../../../utils';
import { WasteHeroLogo1 } from '../../../svgs';

const p = createPrefixer('wh-UI-Responsive-Sidebar-');

const classes = {
  wrapper: p`wrapper`,
  navigationContent: p`navigation-content`,
  mobileNavigationBar: p`mobile-navigation-bar`,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type ResponsiveSidebarProps = {
  userInformationProps: UserInformationProps;
  controlButtonProps: NavigationBarButtonControlsProps;
  menuTreeProps: MenuTreeProps;
  logo?: ReactNode;
};

const NoPaddingDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0px !important;
    overflow-x: hidden;
  }

  .ant-drawer-header-close-only {
    padding: 0px !important;
  }

  .ant-drawer-close {
    display: none;
  }
`;

const ResponsiveSidebarDiv = styled.div`
  background: #f5f5f5;
  width: 100%;
  height: 7% !important;

  .${classes.mobileNavigationBar} {
    padding: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 4rem;
      height: 2rem;
    }
  }

  .navigation-bar-company-logo {
    height: 2rem;
  }

  .ant-btn-icon-only {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
  }

  .navigation-menu-icon {
    height: 100%;
  }

  .${classes.navigationContent} {
    display: none;
  }

  @media screen and (min-width: 992px) {
    min-height: 100%;
    flex: 0 0 280px;
    min-width: 20rem;
    max-width: 20rem;
    width: 20rem;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;

    .${classes.navigationContent} {
      display: revert;
    }
  }
`;

const ResponsiveSidebar: React.FC<ResponsiveSidebarProps> = ({
  userInformationProps,
  controlButtonProps,
  menuTreeProps,
  logo,
}) => {
  const [showMobileNavMenu, setShowMobileNavMenu] = useState<boolean>(false);

  const navigationContent = (
    <NavigationContent
      logo={logo}
      menuTree={<MenuTree {...menuTreeProps} />}
      userInformation={<UserInformation {...userInformationProps} />}
      controlButtons={<NavigationBarButtonControls {...controlButtonProps} />}
    />
  );

  useEffect(() => {
    setShowMobileNavMenu(false);
  }, [menuTreeProps?.selectedKeys]);

  return (
    <ResponsiveSidebarDiv className={classes.wrapper}>
      <Row style={{ height: '100%' }}>
        <Col xs={24} sm={24} md={24} lg={0}>
          <div className={classes.mobileNavigationBar}>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            {!window.WH_SS_NO_LOGO ? (
              logo || <WasteHeroLogo1 style={{ fontSize: '4rem' }} />
            ) : (
              <div />
            )}
            <MenuOutlined
              style={{ fontSize: '1.25rem' }}
              onClick={() => setShowMobileNavMenu((prevState) => !prevState)}
            />
          </div>
        </Col>
        <Col span={24} className={classes.navigationContent}>
          {navigationContent}
        </Col>
      </Row>
      <NoPaddingDrawer
        title=""
        onClose={() => setShowMobileNavMenu(false)}
        open={showMobileNavMenu}
        width={280}
      >
        {navigationContent}
      </NoPaddingDrawer>
    </ResponsiveSidebarDiv>
  );
};

export default ResponsiveSidebar;
