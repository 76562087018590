import { useHistory } from 'react-router';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';
import { Radio, Button } from 'src/antd';
import { RangeSelectorWithRadio } from 'src/ui';
import { SelectYearSimple } from 'src/ui/SelectMoment/SelectYearSimple';
import { SelectMonthSimple } from 'src/ui/SelectMoment/SelectMonthSimple';
import { T } from '@transifex/react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import useMediaQuery from 'use-media-antd-query/es/useMediaQuery';
// eslint-disable-next-line import/no-cycle
import { CollectionDisplay } from '../../../../shared/CollectionDisplay';
import { TableKit } from '../../../../../ui-legacy/table-kit';
import { CollectionCalendar } from './components/calendar';
import {
  getMonthIndex,
  getMonthRangeArray,
  getMonthRangeArrayFromDayjs,
  getShortMonthName,
  getYear,
} from '../../../../../utils';
// eslint-disable-next-line import/no-cycle

const UIWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .query-controls {
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2rem;

    .ant-radio-group {
      display: none;
    }

    .ant-col {
      min-height: 0;
    }

    .ant-row {
    }

    .wh-UI-RangeSelectorWithRadio-wrapper {
      flex-wrap: wrap;

      .ant-select {
        width: 100% !important;
      }
    }

    .ant-picker {
      display: inherit;
      margin-top: 0.5rem;
      width: 100%;
    }
  }

  @media screen and (min-width: 992px) {
    .query-controls {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 2rem;

      .ant-radio-group {
        display: inherit;
      }

      .ant-col {
        min-height: 0;
      }

      .ant-picker {
        display: flex;
      }

      .wh-UI-RangeSelectorWithRadio-wrapper {
        flex-wrap: nowrap;

        .ant-select {
          width: 160px !important;
        }
      }

      .ant-picker {
        display: inherit;
        margin-top: 0rem;
        width: 272px;
      }

      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 100% !important;
      }
    }
  }
`;

const options = [
  { label: <T _str="Calendar view" />, value: 'calendar' },
  { label: <T _str="List view" />, value: 'list' },
];

type DateRangePickerProps = {
  initialRange: [Dayjs, Dayjs];
  onChange: (range: [Dayjs, Dayjs]) => void;
};

type UIProps = {
  rangePickerProps: DateRangePickerProps;
  collections: $TSFixMe;
  loading: boolean;
};

export const UI = ({ rangePickerProps, collections, loading }: UIProps) => {
  const [view, setView] = useState<string>('calendar');
  const [value, setValue] = useState<Dayjs>(rangePickerProps.initialRange?.[0]);
  const history = useHistory();

  const { initialRange, onChange } = rangePickerProps;

  useEffect(() => {
    setValue(initialRange?.[0]);
  }, [initialRange]);

  const veryNarrow = useMediaQuery('(max-width: 420px)');
  const mobile = useMediaQuery('(max-width: 996px)');
  const tablet = useMediaQuery('(max-width: 1200px)');

  useEffect(() => {
    if (mobile) {
      setView('list');
    }
  }, [mobile]);

  const onRightClick = () => {
    onChange(getMonthRangeArrayFromDayjs(dayjs(value).add(1, 'month')));
  };

  const onLeftClick = () => {
    onChange(getMonthRangeArrayFromDayjs(dayjs(value).subtract(1, 'month')));
  };

  const calendarMover = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          fontSize: '1rem',
          alignItems: 'center',
          marginRight: '2rem',
          color: 'rgba(0, 0, 0, 0.85)',
        }}
      >
        <LeftOutlined onClick={() => onLeftClick()} />
      </div>
      <div style={{ fontWeight: 800, fontSize: '1.5rem' }}>
        {dayjs(initialRange?.[0]).format('YYYY')}{' '}
        {getShortMonthName(dayjs(initialRange?.[0]))}
      </div>
      <div
        style={{
          display: 'flex',
          fontSize: '1rem',
          alignItems: 'center',
          marginLeft: '2rem',
          color: 'rgba(0, 0, 0, 0.85)',
        }}
      >
        <RightOutlined onClick={() => onRightClick()} />
      </div>
    </div>
  );

  const dateFormat = veryNarrow ? 'MMM D, YYYY' : 'LL';

  return (
    <UIWrapper>
      <div className="query-controls">
        <Radio.Group
          options={options}
          optionType="button"
          buttonStyle="solid"
          value={view}
          onChange={(e) => setView(e.target.value)}
        />
        {!tablet && view === 'calendar' && calendarMover}
        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
          {view === 'list' ? (
            <RangeSelectorWithRadio
              onChange={(e) => rangePickerProps.onChange(e)}
              value={rangePickerProps.initialRange}
            />
          ) : (
            <>
              <SelectYearSimple
                onChange={(e) =>
                  rangePickerProps.onChange(
                    getMonthRangeArray(
                      getMonthIndex(rangePickerProps.initialRange?.[0]),
                      e
                    )
                  )
                }
                value={parseInt(
                  dayjs(rangePickerProps.initialRange?.[0]).format('YYYY'),
                  10
                )}
                validRange={[2018, 2030]}
              />
              <SelectMonthSimple
                onChange={(e) =>
                  rangePickerProps.onChange(
                    getMonthRangeArray(
                      e,
                      Number(getYear(rangePickerProps.initialRange?.[0]))
                    )
                  )
                }
                value={
                  parseInt(
                    dayjs(rangePickerProps.initialRange?.[0]).format('MM'),
                    10
                  ) - 1
                }
              />
            </>
          )}
          <Button onClick={() => history.push('/collections/download')}>
            <span>
              <T _str="Download" />
            </span>
          </Button>
        </div>
      </div>
      {tablet && view === 'calendar' && (
        <div style={{ marginBottom: '0.5rem' }}>{calendarMover}</div>
      )}
      {loading && <TableKit.Spinner />}
      {!loading && !Object.keys(collections).length && view === 'list' && (
        <TableKit.NoData />
      )}
      {!loading &&
        view !== 'calendar' &&
        Object.values(collections)?.map((collection: $TSFixMe) => (
          <CollectionDisplay
            containers={collection}
            date={dayjs(collection?.[0]?.date).format(dateFormat)}
          />
        ))}
      {!loading && view !== 'list' && (
        <CollectionCalendar
          data={collections}
          onChange={rangePickerProps.onChange}
          value={value}
          setValue={setValue}
        />
      )}
    </UIWrapper>
  );
};
