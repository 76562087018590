/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-underscore-dangle */
import React, {
  FC,
  useMemo,
  useEffect,
  useState,
  useRef,
  forwardRef,
} from 'react';
import { DatePicker, Row, Select } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import styled from 'styled-components';
import { RangePickerProps } from 'antd/lib/date-picker';
import {
  getQuickDateSelectValue,
  quickDateOptionMap,
  quickDateOptions,
  TQuickDateOption,
} from './utils';
import { createPrefixer } from '../../utils';
import { Typography } from '../Typography';

const { RangePicker } = DatePicker;

const p = createPrefixer('wh-UI-RangeSelectorWithRadio-');

const classes = {
  wrapper: p`wrapper`,
};

const StyledRangePicker = styled(RangePicker)`
  z-index: 1;

  transform: translateX(-1px);
  padding-left: 8px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  width: 272px;

  & .ant-picker-input {
    height: 100%;

    input {
      padding-left: 4px;
      border-bottom: 2px solid transparent;
      padding-top: 3px;
      padding-bottom: 3px;
      margin-bottom: -1px;
    }

    input:focus {
      background-color: #e6f7ff;
      border-bottom-color: ${(props) => props.theme.colors.link100};
    }
  }

  &:hover {
    z-index: 2;
  }

  &.ant-picker-focused {
    z-index: 2;
  }

  & .ant-picker-active-bar {
    display: none !important;
  }
`;

const RangePickerWithDropdownClass = forwardRef(
  (props: RangePickerProps, ref) => {
    return (
      <StyledRangePicker
        ref={ref as $TSFixMe}
        {...props}
        popupClassName={props.className}
      />
    );
  }
);

const StyledSelect = styled(Select)`
  z-index: 2;

  & .ant-select-arrow {
    z-index: 2;
  }

  & .ant-select-selector {
    z-index: 1;

    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

    &:focus {
      z-index: 2;
    }
  }
`;

const StyledDropdownRangePicker = styled(RangePickerWithDropdownClass)`
  & .ant-picker-time-panel {
    display: none;
  }

  & .ant-picker-panel-container .ant-picker-panel {
    border-bottom: none !important;
  }

  & .ant-picker-footer {
    & .ant-picker-ranges {
      padding: 4px 8px;
    }

    & .ant-picker-ok {
      width: 100%;
    }

    & .ant-btn {
      width: 100%;
    }
  }

  & .ant-picker-ok {
    & .ant-btn {
      padding: 4px 15px;
      height: 32px;
    }
  }

  & .ant-picker-datetime-panel {
    & .ant-picker-date-panel {
      width: 304px;
    }

    & .ant-picker-content {
      margin-left: 28px;
    }
  }

  & .ant-picker-week-number {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-left: -28px;
    width: 24px;
    height: 24px;
    cursor: default;
    pointer-events: none;
  }

  & .ant-picker-month-btn::after {
    content: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="12" height="12" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 12"%3E%3Cpath fill="%23bfbfbf" d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"%2F%3E%3C%2Fsvg%3E');
    margin-left: 5px;
    color: #bfbfbf;
  }

  & .ant-picker-year-btn {
    margin-left: 16px !important;
  }

  & .ant-picker-year-btn::after {
    content: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="12" height="12" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 12"%3E%3Cpath fill="%23bfbfbf" d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"%2F%3E%3C%2Fsvg%3E');
    margin-left: 5px;
    color: #bfbfbf;
  }

  & .ant-picker-range-arrow {
    display: none;
  }

  & .ant-picker-footer {
    display: none;
  }
` as typeof RangePickerWithDropdownClass;

function mouseDownAndFocus(this: HTMLInputElement) {
  const mouseDownEvent = new Event('mousedown', {
    bubbles: true,
    cancelable: false,
  });

  const timeout = setTimeout(() => {
    this.focus();
    this.dispatchEvent(mouseDownEvent);
  }, 200);

  this.addEventListener(
    'mouseleave',
    () => {
      clearTimeout(timeout);
    },
    { once: true }
  );
}

export const RangeSelectorWithRadio: FC<
  Omit<RangePickerProps, 'showTime' | 'value' | 'onChange' | 'size'> & {
    value?: [Dayjs, Dayjs];
    onChange?: (v: [Dayjs, Dayjs]) => void;
    allowNull?: boolean;
  }
> & {
  classes: typeof classes;
} = ({ style, onChange, value, allowNull, ...props }) => {
  const intialeStateRef = useRef(
    value || (allowNull ? null : quickDateOptionMap.this_week())
  );

  const [internalValue, setInternalValue] = useState(intialeStateRef.current);
  const [open, setOpen] = useState(false);
  const rpRef = useRef();
  const panelWrapperRef = useRef<HTMLDivElement>(null);
  const wrapperRowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!open)
      setTimeout(() => {
        (rpRef?.current as $TSFixMe).blur();
      }, 10);
  }, [open]);

  useEffect(() => {
    if (!internalValue && !allowNull) {
      setInternalValue(intialeStateRef.current);
      return;
    }

    if (onChange && internalValue) {
      onChange(internalValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalValue]);

  useEffect(() => {
    if (
      value &&
      (!value[0]?.isSame(internalValue?.[0]) ||
        !value[1]?.isSame(internalValue?.[1]))
    ) {
      setInternalValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const quickDateSelectValue = useMemo<TQuickDateOption>(
    () => getQuickDateSelectValue(value as [Dayjs, Dayjs]),
    [value]
  );

  const onQuickSelectChange = (v: TQuickDateOption) => {
    const dateRange = quickDateOptionMap[v]();
    onChange?.(dateRange);
  };

  useEffect(() => {
    const inputs = wrapperRowRef.current?.querySelectorAll<HTMLInputElement>(
      '.ant-picker-input input'
    );

    inputs?.forEach((input) => {
      input.addEventListener('mouseover', mouseDownAndFocus);
    });

    return () => {
      inputs?.forEach((input) => {
        input.removeEventListener('mouseover', mouseDownAndFocus);
      });
    };
  }, []);

  const firstDayOfWeek = dayjs().localeData().firstDayOfWeek();

  return (
    <Row ref={wrapperRowRef} wrap={false} className={classes.wrapper}>
      <StyledSelect
        value={quickDateSelectValue}
        onChange={(v) => onQuickSelectChange(v as TQuickDateOption)}
        options={quickDateOptions}
        style={{ width: 120 }}
        showArrow
      />
      <StyledDropdownRangePicker
        format="ll"
        value={internalValue}
        onChange={(v) => {
          if (v && v[0] && v[1]) {
            setInternalValue?.([v[0].startOf('day'), v[1].endOf('day')]);
          } else {
            setInternalValue(null);
            // clears the value
          }
        }}
        placement="bottomRight"
        inputReadOnly
        ref={rpRef}
        open={open}
        onOpenChange={setOpen}
        // FIX LATER
        // eslint-disable-next-line react/no-unstable-nested-components
        panelRender={(panel) => {
          return <div ref={panelWrapperRef}>{panel}</div>;
        }}
        // FIX LATER
        // eslint-disable-next-line react/no-unstable-nested-components
        dateRender={(current) => (
          <>
            {current.day() === firstDayOfWeek && (
              <div className="ant-picker-week-number">
                <Typography style={{ color: '#BFBFBF' }}>
                  {dayjs(current).endOf('week').isoWeek()}
                </Typography>
              </div>
            )}
            <div
              onClick={() => {
                const okButton =
                  panelWrapperRef.current?.querySelector<HTMLButtonElement>(
                    '.ant-picker-ok button'
                  );
                setTimeout(() => {
                  okButton?.click();
                }, 30);
              }}
            >
              <div className="ant-picker-cell-inner">{current.date()}</div>
            </div>
          </>
        )}
        {...props}
        showTime
      />
    </Row>
  );
};

RangeSelectorWithRadio.classes = classes;
