import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { T } from '@transifex/react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import { Layout1 } from '../../../layout';
import { BreadCrumb } from '../../../layout/breadcrumb';
import { Header } from '../../../layout/header';
import { UI } from './ui';
import { GetTicket } from '../../api/ticket';
import { PropertyContext } from '../../../../context/property-context';
import { TicketTag } from './components/TicketTag';
import { ContainerProductDetail } from './components/ContainerProductDetail';
import { BulkWasteDetails } from './components/BulkWasteDetails';
// eslint-disable-next-line import/no-cycle
import { useGetToken } from '../../../../api/config';

export const TicketView = () => {
  const token = useGetToken();
  const { id: propertyId } = useContext(PropertyContext);

  const history = useHistory();
  const { ticketId } = useParams<{ ticketId: string }>();

  const { data } = useQuery(
    ['get-container-ticket', propertyId, ticketId],
    () =>
      GetTicket({
        property_id: propertyId,
        token,
        ticket_id: ticketId,
      })
  );

  const ticketData = data?.data;

  return (
    <Layout1
      breadcrumb={
        <BreadCrumb
          title={<T _str="Ticket" />}
          suffix={<TicketTag ticketStatus={ticketData?.status} />}
          onBack={() => history.goBack()}
        />
      }
      header={<Header title={<T _str="Ticket details" />} />}
      mainContent={
        <UI
          ticketStatus={ticketData?.status}
          ticketType={ticketData?.ticket_type}
          description={ticketData?.description}
          detail={
            // eslint-disable-next-line no-nested-ternary
            ticketData?.container_product ? (
              <ContainerProductDetail
                container={
                  ticketData?.container
                    ? {
                        wasteFraction: {
                          name: ticketData?.container?.waste_fraction?.name,
                        },
                        containerType: {
                          name: ticketData?.container?.container_type?.name,
                        },
                        pickupSetting: {
                          name: ticketData?.container?.pickup_setting?.name,
                        },
                      }
                    : undefined
                }
                containerProduct={{
                  wasteFraction: {
                    name: ticketData?.container_product?.waste_fraction?.name,
                  },
                  containerType: {
                    name: ticketData?.container_product?.container_type?.name,
                  },
                  pickupSetting: {
                    name: ticketData?.container_product?.pickup_setting?.name,
                  },
                }}
              />
            ) : ticketData?.bulk_waste_items?.length > 0 ? (
              <BulkWasteDetails
                bulkWasteItems={ticketData?.bulk_waste_items?.map(
                  (e: {
                    waste_fraction: { name: string };
                    quantity: number;
                    note: string;
                  }) => ({
                    wasteFraction: { name: e?.waste_fraction?.name },
                    quantity: e?.quantity,
                    note: e?.note,
                  })
                )}
              />
            ) : undefined
          }
          images={ticketData?.attachments}
          container={
            ticketData?.container
              ? `${ticketData?.container?.waste_fraction?.name} | ${ticketData?.container?.container_type?.name}`
              : undefined
          }
          createdAt={dayjs(ticketData?.created_at).format('LL')}
          preferredDate={
            ticketData?.preferred_date
              ? dayjs(ticketData?.preferred_date).format('LL')
              : undefined
          }
        />
      }
    />
  );
};
