import React, { ReactNode } from 'react';
import { Row, Col, Divider } from 'src/antd';
import styled from 'styled-components';
import { createPrefixer } from '../../../../../utils';
import { WasteHeroLogo1 } from '../../../../../svgs';

const p = createPrefixer('wh-UI-Responsive-Sidebar-Navigation-Content-');

const classes = {
  wrapper: p`wrapper`,
  logoContent: p`logo-content`,
  menuTreeContent: p`menu-tree-content`,
  userInformationContent: p`user-information-content`,
  controlButtonContent: p`control-button-content`,
};

export type NavigationContentProps = {
  logo?: ReactNode;
  menuTree: ReactNode;

  userInformation: ReactNode;
  controlButtons: ReactNode;
};

const NavigationContentDiv = styled.div`
  background: ${(props) => props.theme.colors.dark5};
  height: 100%;
  display: flex;

  .${classes.logoContent} {
    display: none;
  }

  .${classes.menuTreeContent} {
    padding: 16px 12px 0;
    margin: 0;
    height: fit-content;
  }

  .ant-menu-inline {
    border: 0px !important;
  }

  .${classes.userInformationContent} {
    margin: 1rem 1rem 0rem 1rem;
    height: fit-content;
    max-width: 15rem;
  }

  .${classes.controlButtonContent} {
    margin: 0 1rem 1rem 1rem;
    height: fit-content;
  }

  .ant-divider {
    display: none;
  }

  @media screen and (min-width: 992px) {
    background: ${(props) => props.theme.colors.dark5};

    .${classes.logoContent} {
      display: inherit;
      padding: 24px;
      height: fit-content;

      img,
      svg {
        width: 100%;
        max-width: 180px;
        height: fit-content;
        max-height: 250px;
      }
    }

    .${classes.userInformationContent} {
      margin: 1rem 1rem 1rem 1rem;
    }

    .${classes.controlButtonContent} {
      margin: 0 1rem 2rem 1rem;
    }

    .ant-divider {
      display: block;
      margin: 0 !important;
    }
  }
`;

const NavigationContent: React.FC<NavigationContentProps> = ({
  logo,
  menuTree,
  controlButtons,
  userInformation,
}) => {
  return (
    <NavigationContentDiv className={classes.wrapper}>
      <Row gutter={[16, 16]} style={{ minHeight: '100%' }}>
        <Col span={24}>
          {!window.WH_SS_NO_LOGO && (
            <div className={classes.logoContent}>
              {logo || <WasteHeroLogo1 style={{ fontSize: '200px' }} />}
            </div>
          )}
          <div className={classes.menuTreeContent}>{menuTree}</div>
        </Col>
        <Col span={24} style={{ alignSelf: 'flex-end' }}>
          <div>
            <Divider />
          </div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div className={classes.userInformationContent}>
            {userInformation}
          </div>
          <div>
            <Divider />
          </div>
          <div className={classes.controlButtonContent}>{controlButtons}</div>
        </Col>
      </Row>
    </NavigationContentDiv>
  );
};

export default NavigationContent;
