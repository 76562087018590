import React, { ReactNode, useRef, useState } from 'react';
import { Col, Row, Upload, Button, Empty, UploadFile, Image } from 'antd';
import { DeleteOutlined, EyeOutlined, UploadOutlined } from '@ant-design/icons';
import { UploadProps } from 'antd/es/upload';
import { RcFile } from 'antd/lib/upload';
import styled from 'styled-components';

import {
  TX_ATTACHMENTS,
  TX_OPTIONAL,
  TX_PREVIEW,
  TX_REMOVE,
  TX_UPLOAD,
} from 'src/storybook_transifex';
import { toBase64 } from 'src/utils/upload';
import { Typography } from '../Typography';
import { ConfirmDeleteFile } from './ConfirmDelete';
import { WHTooltip } from '../WhTooltip';
import Modal from '../Modal';

const imageTypes = ['image/jpeg', 'image/jpg', 'image/png'];

export type TUploadMultipleWithButtonProps = UploadProps & {
  optionalMark?: boolean;
  /**
   * show upload button in the bottom of the list.
   * default to false.
   *
   */
  showUploadButtonBottom?: boolean;
  /**
   * Show the top header
   * defaul to true
   */
  showHeader?: boolean;
  /** showEmpty
   * show empty view when no file.
   * default to false
   */
  showEmpty?: boolean;
  confirmOnRemove?: boolean;
};

const StyledUpload = styled(Upload)`
  & .ant-upload-list-item-list-type-picture-card {
    padding: 0px;
  }

  & .ant-upload-span {
    padding: 8px;
  }
`;

const UploadMultipleWithButton: React.FC<TUploadMultipleWithButtonProps> = ({
  fileList,
  onRemove,
  onChange,
  style,
  className,
  disabled,
  optionalMark,
  showHeader = true,
  showUploadButtonBottom = false,
  confirmOnRemove = true,
  showEmpty = false,
  ...props
}) => {
  const [previewData, setPreviewData] = useState<{
    image: string;
    title: ReactNode;
    open: boolean;
  }>({
    image: '',
    title: '',
    open: false,
  });

  const base64ImagesCache = useRef<Record<string, string>>({});

  const imageFileList = fileList?.filter((file) =>
    imageTypes.includes(file.type ?? '')
  );

  const otherFileList = fileList?.filter(
    (file) => !imageTypes.includes(file.type ?? '')
  );

  // @ts-expect-error wip
  const confirmRemove: UploadProps['onRemove'] = async (...args) => {
    if (onRemove) {
      if (confirmOnRemove) {
        if (await ConfirmDeleteFile()) {
          return onRemove(...args);
        }
        return false;
      }
      return onRemove(...args);
    }

    if (confirmOnRemove) {
      return ConfirmDeleteFile();
    }

    return true;
  };

  const uplaodButton = (
    <Upload
      showUploadList={false}
      style={{ width: '100%' }}
      accept=""
      fileList={fileList}
      onRemove={confirmRemove}
      onChange={(info) => {
        const file = info.fileList.find((f) => f.uid === info.file.uid);
        if (file?.type && imageTypes?.includes(file.type)) {
          toBase64(file.originFileObj as RcFile).then((res) => {
            base64ImagesCache.current = {
              ...base64ImagesCache.current,
              [file.uid]: res,
            };
          });
        }
        onChange?.(info);
      }}
      disabled={disabled}
      {...props}
    >
      <Button
        disabled={disabled}
        htmlType="button"
        type="text"
        icon={<UploadOutlined style={{ marginRight: 10 }} />}
      >
        {TX_UPLOAD}
      </Button>
    </Upload>
  );

  const handlePreview = async (file: UploadFile) => {
    let preview: string;
    const cachedPreview = file?.url || base64ImagesCache.current[file.uid];

    if (cachedPreview) {
      preview = cachedPreview;
    } else {
      preview = await toBase64(file.originFileObj as RcFile);
    }

    setPreviewData({
      image: preview,
      title: file.name || TX_PREVIEW,
      open: true,
    });
  };

  return (
    <>
      <Modal
        visible={previewData.open}
        title={previewData.title}
        footer={null}
        onClose={() => {
          setPreviewData((prev) => ({
            ...prev,
            open: false,
          }));
        }}
        fullscreen
      >
        <Image
          wrapperStyle={{
            paddingLeft: 80,
            paddingRight: 80,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
          }}
          preview={false}
          alt="modal-preview"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }}
          src={previewData.image}
        />
      </Modal>
      <Row
        className={className}
        gutter={[0, 8]}
        style={{ width: '100%', ...style }}
      >
        {showHeader && (
          <Col xs={24}>
            <Row
              style={{ width: '100%' }}
              align="middle"
              justify="space-between"
            >
              <Col>
                <Typography>{TX_ATTACHMENTS}</Typography>
                {optionalMark && (
                  <Typography type="secondary"> ({TX_OPTIONAL})</Typography>
                )}
              </Col>
              <Col>{uplaodButton}</Col>
            </Row>
          </Col>
        )}
        <Col
          style={{
            display: imageFileList?.length === 0 ? 'none' : 'inline-block',
            // to prevent a ui bug where upload has a height with no data
          }}
          xs={24}
        >
          {/* these two bottom uploads only to show data seperate lists */}
          <StyledUpload
            onRemove={confirmRemove}
            onChange={({ file }) =>
              onChange?.({
                file,
                fileList: fileList?.filter((f) => f.uid !== file.uid) ?? [],
              })
            }
            listType="picture-card"
            fileList={imageFileList}
            disabled={disabled}
            onPreview={handlePreview}
            showUploadList={{
              removeIcon: (
                <WHTooltip title={TX_REMOVE}>
                  <DeleteOutlined />
                </WHTooltip>
              ),
              previewIcon: (
                <WHTooltip title={TX_PREVIEW}>
                  <EyeOutlined />
                </WHTooltip>
              ),
            }}
          />
        </Col>
        <Col
          style={{
            display: otherFileList?.length === 0 ? 'none' : 'inline-block',
          }}
          xs={24}
        >
          <Upload
            accept=""
            onRemove={confirmRemove}
            onChange={({ file }) =>
              onChange?.({
                file,
                fileList: fileList?.filter((f) => f.uid !== file.uid) ?? [],
              })
            }
            listType="text"
            fileList={otherFileList}
            disabled={disabled}
          />
        </Col>
        {showEmpty && !fileList?.length && (
          <Col
            xs={24}
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '1rem',
            }}
          >
            <Empty />
          </Col>
        )}
        {showUploadButtonBottom && <Col>{uplaodButton}</Col>}
      </Row>
    </>
  );
};

export default UploadMultipleWithButton;
