import React, { useContext } from 'react';
import { Col, Form, Input, Row } from 'src/antd';
import { T } from '@transifex/react';
import { useParams } from 'react-router-dom';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { VALIDATION_MESSAGE } from '../../../../../../../../utils/validation';
import { PropertyContext } from '../../../../../../../../context/property-context';
import { InputPhone } from '../../../../../../../shared/InputPhone';

const Content = () => {
  const { ticketTypes } = useContext(PropertyContext);
  const { ticketTypeId } = useParams<{ ticketTypeId: string }>();

  const ticketType: $TSFixMe = ticketTypes?.filter(
    (e: { id: string }) => e.id === ticketTypeId
  );

  const {
    portalContactInformationNameRequired: isNameRequired,
    portalContactInformationPhoneNumberRequired: iPhoneRequired,
    portalContactInformationEmailRequired: isEmailRequired,
  } = ticketType?.[0] || {};

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Form.Item
          label={<T _str="Name" />}
          name={['contactInfo', 'name']}
          rules={[
            {
              required: isNameRequired,
              message: VALIDATION_MESSAGE.REQUIRED,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label={<T _str="Phone" />}
          name={['contactInfo', 'phoneNumber']}
          rules={[
            {
              required: iPhoneRequired,
              message: VALIDATION_MESSAGE.REQUIRED,
            },
            ({ getFieldValue }) => ({
              validator: () => {
                const phoneNumber = getFieldValue([
                  'contactInfo',
                  'phoneNumber',
                ]);

                if (!phoneNumber) return Promise.resolve();

                if (isValidPhoneNumber(phoneNumber)) return Promise.resolve();

                return Promise.reject();
              },
              message: VALIDATION_MESSAGE.PHONE_NUMBER,
            }),
          ]}
        >
          <InputPhone />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          rules={[
            { type: 'email', message: VALIDATION_MESSAGE.EMAIL },
            {
              required: isEmailRequired,
              message: VALIDATION_MESSAGE.REQUIRED,
            },
          ]}
          label={<T _str="Email" />}
          name={['contactInfo', 'email']}
        >
          <Input type="email" />
        </Form.Item>
      </Col>
    </Row>
  );
};

const BreadCrumbTitle = <T _str="Contact information" />;

export const FormContactInfo = { Content, BreadCrumbTitle };
