import { SyncOutlined } from '@ant-design/icons';
import { T } from '@transifex/react';
import { Typography } from 'src/ui/Typography';
import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Space,
} from 'src/antd';
import React, { useMemo, useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import useMediaQuery from 'use-media-antd-query/es/useMediaQuery';
import { apiErrorUtils } from '../../../../../api/consts/error';
import { apiNotification } from '../../../../../api/notifications';

import { VALIDATION_MESSAGE } from '../../../../../utils/validation';
import { TNotificationMode, useSmsServiceStore } from '../store';
import { notificationModeContact2Map } from './consts';
import { getCookie, setCookie } from '../../../../../utils/cookies';
import {
  emailCodeCookie,
  emailServiceAddress,
  emailServiceMode,
  smsCodeCookie,
  smsServiceMode,
  smsServicePhoneNumber,
} from '../../../../../consts/cookie-consts';
import { InputPhone } from '../../../../shared/InputPhone';
// eslint-disable-next-line import/no-cycle
import { useGetToken } from '../../../../../api/config';

type TFormSchema = {
  step: 0 | 1;
  phoneNumber?: string;
  email?: string;
  notificationCode: string;
};

const FormGetVerificationId = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm<TFormSchema>();
  const token = useGetToken();
  const step: TFormSchema['step'] = Form.useWatch('step', form);
  const { mode } = useSmsServiceStore.getState();
  const isMobile = useMediaQuery('(max-width: 996px)');

  const initialValues = useMemo<TFormSchema>(
    () => ({
      step: 0,
      email: '',
      phoneNumber: '',
      notificationCode: '',
    }),
    []
  );

  const sendCode = async () => {
    const { phoneNumber, email } = form.getFieldsValue();
    // send the verification code.
    const { data } = await apiNotification.mutateSendVerificationCode({
      token,
      verification_type: mode,
      email,
      phone_number: phoneNumber,
    });

    if (data) {
      form.setFieldsValue({ step: 1, email, phoneNumber });
      message.success(<T _str="Verification code sent" />);
    } else {
      form.setFieldsValue({ step: 0, email, phoneNumber });
      message.error(<T _str="Error occured" />);
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      if (step === 0) {
        await sendCode();
      } else {
        const { phoneNumber, email, notificationCode } = form.getFieldsValue();
        // verify the code. step === 1
        const { data }: $TSFixMe = await apiNotification.mutateVertifyCode({
          token,
          verification_type: mode,
          email,
          phone_number: phoneNumber,
          code: notificationCode,
        });
        useSmsServiceStore
          .getState()
          .setVerificationCode(data?.verification_id, {
            email,
            phoneNumber,
            mode,
          });
        if (data?.verification_id) {
          const codeCookie = mode === 'sms' ? smsCodeCookie : emailCodeCookie;
          const serviceValueCookie =
            mode === 'sms' ? smsServicePhoneNumber : emailServiceAddress;
          const serviceModeCookie =
            mode === 'sms' ? smsServiceMode : emailServiceMode;

          setCookie(codeCookie, data.verification_id, 1 / 24);
          setCookie(serviceValueCookie, phoneNumber || '', 1 / 24);
          setCookie(serviceModeCookie, mode, 1 / 24);
        }
      }
      setIsSubmitting(false);
    } catch (e) {
      apiErrorUtils.handleError(e);
      setIsSubmitting(false);
    }
  };

  if (getCookie(smsCodeCookie) && mode === 'sms') {
    useSmsServiceStore
      .getState()
      .setVerificationCode(getCookie(smsCodeCookie), {
        phoneNumber: getCookie(smsServicePhoneNumber),
        mode: getCookie(smsServiceMode) as TNotificationMode,
      });
  }

  if (getCookie(emailCodeCookie) && mode === 'email') {
    useSmsServiceStore
      .getState()
      .setVerificationCode(getCookie(emailCodeCookie), {
        phoneNumber: getCookie(emailServiceAddress),
        mode: getCookie(emailServiceMode) as TNotificationMode,
      });
  }

  return (
    <Form
      requiredMark="optional"
      layout="vertical"
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
    >
      <Space style={{ marginBottom: 24 }}>
        <Typography weight="semi-bold" variant="h5">
          {mode === 'sms' && <T _str="Please enter the mobile phone number." />}
          {mode === 'email' && <T _str="Please enter the email." />}
        </Typography>
      </Space>
      {mode === 'sms' && (
        <Form.Item
          name="phoneNumber"
          label={<T _str="Your mobile number" />}
          rules={[
            { required: true, message: VALIDATION_MESSAGE.REQUIRED },
            ({ getFieldValue }) => ({
              validator: () => {
                const phoneNumber = getFieldValue('phoneNumber');

                if (isValidPhoneNumber(phoneNumber)) return Promise.resolve();
                return Promise.reject();
              },
              message: VALIDATION_MESSAGE.PHONE_NUMBER,
            }),
          ]}
        >
          <InputPhone
            style={{ width: '100%', maxWidth: isMobile ? 500 : 300 }}
            disabled={step === 1 || isSubmitting}
          />
        </Form.Item>
      )}
      {mode === 'email' && (
        <Form.Item
          name="email"
          label={<T _str="Your email address" />}
          rules={[
            { required: true, message: VALIDATION_MESSAGE.REQUIRED },
            { type: 'email', message: VALIDATION_MESSAGE.EMAIL },
          ]}
        >
          <Input
            style={{ width: '100%', maxWidth: isMobile ? 500 : 300 }}
            type="email"
            disabled={step === 1 || isSubmitting}
          />
        </Form.Item>
      )}
      <Divider />
      {step === 1 && (
        <Form.Item
          name="notificationCode"
          label={<T _str="Notification code" />}
          rules={[{ required: true, message: VALIDATION_MESSAGE.REQUIRED }]}
        >
          <Input
            style={{ width: '100%', maxWidth: isMobile ? 500 : 300 }}
            disabled={isSubmitting}
          />
        </Form.Item>
      )}
      {step === 1 && (
        <Alert
          style={{ marginBottom: 16 }}
          showIcon
          type="info"
          message={
            <Typography variant="h6" weight="semi-bold">
              <T _str="We have sent you the code." />
            </Typography>
          }
          description={
            <T
              _str="We have send you a code to your {method}. Please enter the code you recieved to verify. It might take up to 2 minutes before you recieve it. If you didn’t recieve the code, please make sure the number you put in is correct."
              method={notificationModeContact2Map[mode]}
            />
          }
        />
      )}
      <Row gutter={[8, 8]}>
        {step === 1 && (
          <Col xs={isMobile ? 24 : 8}>
            <Button
              style={{ width: '100%', maxWidth: 500 }}
              icon={<SyncOutlined />}
              onClick={sendCode}
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              <span>
                <T _str="Resend code" />
              </span>
            </Button>
          </Col>
        )}
        <Col xs={step === 1 && !isMobile ? 8 : 24}>
          <Form.Item shouldUpdate noStyle>
            {({ isFieldsTouched }) => (
              <Button
                htmlType="submit"
                type="primary"
                loading={isSubmitting}
                disabled={!isFieldsTouched() || isSubmitting}
                style={{
                  width: '100%',
                  maxWidth: step === 1 || isMobile ? 500 : 220,
                }}
              >
                {step === 0 && <T _str="Send me a verification code" />}
                {step === 1 && <T _str="Verify" />}
              </Button>
            )}
          </Form.Item>
        </Col>
        {step === 1 && (
          <Col xs={24}>
            <Button
              style={{ padding: 0 }}
              type="link"
              onClick={() => form.setFieldsValue(initialValues)}
            >
              {mode === 'sms' ? (
                <T _str="Try another phone number" />
              ) : (
                <T _str="Try another email address" />
              )}
            </Button>
          </Col>
        )}
      </Row>
      <Form.Item name="step" hidden />
    </Form>
  );
};

export default FormGetVerificationId;
