import React, { FC } from 'react';
import styled from 'styled-components';
import { T } from '@transifex/react';
import { createPrefixer } from '../../utils';

const p = createPrefixer('wh-UI-Breadcrumb-simple-');

const classes = {
  wrapper: p`wrapper`,
  title: p`title`,
  description: p`description`,
  responsiveSteps: p`responsive-steps`,
  stepContainer: p`step-container`,
  firstStep: p`first-step`,
  totalSteps: p`total-steps`,
};

export type StepsProps = {
  totalSteps: number;
  currentStep: number;
};

const StepsDiv = styled.div`
  .${classes.responsiveSteps} {
    display: flex;
    flex-direction: row;
    justify-content: end;
  }

  .${classes.firstStep} {
    font-size: 0.875rem;
    line-height: 1.375rem;
    font-weight: 600;
    color: #0f3f6b;
  }

  .${classes.totalSteps} {
    font-size: 0.875rem;
    line-height: 1.375rem;
    font-weight: 600;
    color: #909da9;
  }

  .${classes.stepContainer} {
    display: none;
  }
  @media screen and (min-width: 992px) {
    display: flex;
    justify-content: start;

    .${classes.responsiveSteps} {
      display: none;
    }

    .${classes.stepContainer} {
      display: inherit;
    }

    .${classes.title} {
      margin-bottom: 1rem;
    }
  }
`;

export const StepProgression: FC<StepsProps> = ({
  totalSteps,
  currentStep,
}) => {
  return (
    <StepsDiv className={classes.wrapper}>
      <div className={classes.responsiveSteps}>
        <div className={classes.firstStep}>
          <T _str="{currentStep}" currentStep={currentStep} />
        </div>
        &nbsp;
        <div className={classes.totalSteps}>
          <T _str="/ {totalSteps}" totalSteps={totalSteps} />
        </div>
      </div>
    </StepsDiv>
  );
};
