import React from 'react';
import dayjs from 'dayjs';
import { DatePicker as BaseDatePicker, DatePickerProps } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import { object } from 'src/utils/object';

const { RangePicker: BaseRangePicker } = BaseDatePicker;

type TWhDatePickerProps = Omit<DatePickerProps, 'dateRender'> & {
  showTime?: boolean;
};

const WhDatePicker = ({ onChange, ...props }: TWhDatePickerProps) => {
  const { showTime } = props;

  return (
    <BaseDatePicker
      {...object(!showTime && { format: 'LL' })}
      {...props}
      onChange={(momentDate, dateString) => {
        onChange?.(
          momentDate,
          // eslint-disable-next-line no-nested-ternary
          showTime
            ? dateString
            : momentDate
            ? dayjs(momentDate).format('YYYY-MM-DD')
            : ''
        );
      }}
    />
  );
};

type TWHRangePickerProps = Omit<RangePickerProps, 'dateRender'> & {
  showTime?: boolean;
};

const WhRangePicker = ({ onChange, ...props }: TWHRangePickerProps) => {
  const { showTime } = props;
  return (
    <BaseRangePicker
      {...object(!showTime && { format: 'LL' })}
      {...props}
      onChange={(momentDate, dateString) => {
        onChange?.(
          momentDate,
          // eslint-disable-next-line no-nested-ternary
          showTime
            ? dateString
            : momentDate
            ? [
                dayjs(momentDate?.[0]).format('YYYY-MM-DD'),
                dayjs(momentDate?.[1]).format('YYYY-MM-DD'),
              ]
            : ['', '']
        );
      }}
    />
  );
};

WhDatePicker.RangePicker = WhRangePicker;

const DATE_PICKER_FORMAT = 'LL';
const DATE_PICKER_FORMAT_TIME = 'LLL';

WhDatePicker.FORMATS = { DATE_PICKER_FORMAT, DATE_PICKER_FORMAT_TIME };

export { WhDatePicker };
