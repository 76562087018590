import React, { ReactNode } from 'react';
import { Row, Col, Button, Space } from 'src/antd';
import { T } from '@transifex/react';
import styled from 'styled-components';

import { createPrefixer } from 'src/utils';
import { EnvironmentOutlined, UserOutlined } from '@ant-design/icons';

const p = createPrefixer('wh-UI-Responsive-Sidebar-user-information-');

const classes = {
  information: p`address-paragraph`,
};

export type UserInformationProps = {
  authenticated: boolean;
  information: {
    name?: string;
    addresses: ReactNode;
  };
  onClick: () => void;
};

const UserInformationDiv = styled.div`
  width: 100%;
  padding: 1rem;
  height: fit-content;

  .${classes.information} {
    color: ${(props) => props.theme.colors.dark100};
    width: 100%;
    font-size: 14px;
  }

  .ant-btn-link {
    padding: 0 !important;
  }

  @media screen and (min-width: 992px) {
    .${classes.information} {
      font-size: 1rem;
    }
  }
`;

const UserInformation: React.FC<UserInformationProps> = ({
  authenticated,
  information,
  onClick,
}) => {
  return (
    <UserInformationDiv>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          {information.name && (
            <div className={classes.information} style={{ marginBottom: 16 }}>
              <Space size={8} align="start">
                <UserOutlined style={{ fontSize: 20 }} />
                {information.name}
              </Space>
            </div>
          )}
          <div className={classes.information}>
            <Space size={8} align="start">
              <EnvironmentOutlined style={{ fontSize: 20 }} />
              {information.addresses}
            </Space>
          </div>
        </Col>
        {!authenticated && onClick && (
          <Col span={24}>
            <Button type="link" onClick={onClick}>
              <span>
                <T _str="Change address" />
              </span>
            </Button>
          </Col>
        )}
      </Row>
    </UserInformationDiv>
  );
};

export default UserInformation;
