import React from 'react';
import './index.css';
import { createRoot } from 'react-dom/client';
import 'dayjs/locale/da';
import isoWeek from 'dayjs/plugin/isoWeek';
import duration from 'dayjs/plugin/duration';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';

import { ErrorBoundary } from './sentry';
import './transifex';
import { RouterProvider } from './context/routing';
import { App } from './app';

dayjs.extend(isoWeek);
dayjs.extend(duration);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);

if (window.WH_SS_INIT_ROUTE) {
  window.usingAsEmbedded = true;
}
const root = createRoot(
  document.getElementById(window.WH_SS_ROOT_ELEMENT_ID || 'root')!
);
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <RouterProvider>
        <App />
      </RouterProvider>
    </ErrorBoundary>
  </React.StrictMode>
);
