import React, { FC } from 'react';
import { T } from '@transifex/react';
import { Form, Space } from 'antd';

import { Typography } from 'src/ui';

import { CustomFieldInputProps, TTicketCustomFieldType } from './types';
import { customFieldsNamesMap } from './mappers';
import NumberCustomField from './components/NumberCustomField';
import SelectCustomField from './components/SelectCustomField';
import TextAreaCustomField from './components/TextAreaCustomField';
import SignatureCustomField from './components/SignatureCustomField';

const customFieldsInputsTypeMap: Record<
  TTicketCustomFieldType,
  FC<CustomFieldInputProps>
> = {
  NUMBER: NumberCustomField,
  SELECT: SelectCustomField,
  TEXT_AREA: TextAreaCustomField,
  MULTI_SELECT: SelectCustomField,
  SIGNATURE: SignatureCustomField,
};

export default function CustomFieldsFormSection() {
  const customFields = Form.useWatch(['customFields']);

  return (
    <Form.List name="customFields">
      {(fields) => {
        if (!fields?.length) {
          return null;
        }

        return (
          <Space
            direction="vertical"
            style={{ width: '100%', margin: '16px 0' }}
          >
            <Typography weight="semi-bold" variant="h5">
              <T _str="Custom fields" />
            </Typography>
            {fields.map((field) => {
              const config = customFields?.[field.key];
              if (!config) {
                return null;
              }

              const inputType = config.input_type as TTicketCustomFieldType;
              const Component = customFieldsInputsTypeMap[inputType];

              return (
                <Component
                  {...config}
                  fieldName={[field.name, customFieldsNamesMap[inputType]]}
                />
              );
            })}
          </Space>
        );
      }}
    </Form.List>
  );
}
