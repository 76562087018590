import React from 'react';
import { T } from '@transifex/react';
import { Row, Space } from 'antd';
import styled from 'styled-components';
import useMediaQuery from 'use-media-antd-query/es/useMediaQuery';

import { useCompany } from 'src/app/Company.store';
import { LanguagePicker } from 'src/components/I18n';
import ApiUrl from 'src/components/layout/header/ApiUrl';
import { WasteHeroLogo1 } from 'src/svgs';
import { Typography } from 'src/ui';

import { LoginContainer, LoginContainerProps } from './LoginContainer';

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
  }
`;

const Left = styled.div`
  width: 100%;
  flex-grow: 1;
  padding: 32px;
`;

const Right = styled.div<{ $bg?: string }>`
  width: 100%;
  flex-grow: 1;
  padding: 60px 32px;
  background-color: ${({ theme }) => theme.colors.dark100};
  background-image: url(${({ $bg }) => $bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-blend-mode: overlay;

  @media screen and (min-width: 1200px) {
    padding: 80px;
  }
`;

const RightContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.light100};
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  white-space: pre-line;

  span,
  p,
  a {
    color: #ffffff;
  }

  a {
    text-decoration: underline;
  }
`;

const Footer = styled.div`
  width: 100%;
  padding: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  align-self: flex-end;
  background-color: ${({ theme }) => theme.colors.light100};
`;

const PoweredBy = styled(Typography)`
  width: 100%;
  text-align: center;

  @media screen and (min-width: 1200px) {
    margin-left: 44px;
    width: unset;
  }
`;

export const LayoutLogin = (props: LoginContainerProps) => {
  const { data: company } = useCompany();
  const isMobile = useMediaQuery('(max-width: 1200px)');
  const loginPageSettings = company.page_description_settings?.find(
    (setting) => setting?.page === 'login'
  );

  const headerJsx = (
    <Space>
      <ApiUrl />
      <LanguagePicker style={{ width: '100%' }} />
    </Space>
  );

  return (
    <Wrapper>
      <Content>
        <Left>
          <Row
            align="middle"
            justify="space-between"
            style={{ marginBottom: 16 }}
          >
            {company.logo ? (
              <img src={company.logo} height={32} alt={company.name} />
            ) : (
              <WasteHeroLogo1 style={{ fontSize: 64 }} />
            )}
            {isMobile && headerJsx}
          </Row>
          <LoginContainer {...props} />
        </Left>
        <Right $bg={company.background_image_for_login_page}>
          <RightContent>
            {!isMobile && <Row justify="end">{headerJsx}</Row>}
            <Title variant="h3" weight="semi-bold" ellipsis={false}>
              {loginPageSettings?.title}
            </Title>
            {loginPageSettings?.sections?.map((section, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Section key={index}>
                {section.title && (
                  <Typography variant="h5" weight="semi-bold" ellipsis={false}>
                    {section.title}
                  </Typography>
                )}
                {section.description && (
                  <Typography ellipsis={false}>
                    {section.description}
                  </Typography>
                )}
                {section.link_url && (
                  <a target="_blank" href={section.link_url} rel="noreferrer">
                    {section.link_label || section.link_url}
                  </a>
                )}
              </Section>
            ))}
          </RightContent>
        </Right>
      </Content>
      <Footer>
        <Typography variant="h6" weight="semi-bold">
          {company.name}
        </Typography>
        {company.phone_number && (
          <Typography variant="h6" weight="semi-bold">
            {company.phone_number}
          </Typography>
        )}
        {/* TODO: add company email */}
        <PoweredBy variant="h6" weight="semi-bold">
          <T _str="Powered by WasteHero" />
        </PoweredBy>
      </Footer>
    </Wrapper>
  );
};
